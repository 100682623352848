import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {CartServices1} from '../../pages/services/CartServices';
import Typography from '@mui/material/Typography';
import { CodeBlock, dracula } from 'react-code-blocks';
import content from '../../content/servicesPage1'
import styles from '../../styles/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import "swiper/css";

const items = content;
const code = `$rows->shift(); //remove head row
$this->rows = $rows;
$result = Reference::whereHas('services', function ($query) {
  $query->where('checked', false);
})->get();

$filtered = collect();
$result->each(function ($reference) use ($rows, $filtered) {
  $services = $reference->services()->get();
  //Filter rows
  $filter = $this->filterRows($rows, $reference, $services);
  $filteredRows = $this->foundRows($rows, $reference);

  $filtered->put($reference->client_number, [
    'rows' => $filter,
    'filtered' => $filter->count(),
    'found' => $filteredRows->count(),
    'services' => $services->count()
  ]);
});
$price = new Price();
$price->id = $reference->referenceClientPrice->id;
$price->currency_id = $reference->currency_id;`;

export default function ServiceOne() {
    return (
        <Grid container sx={styles.serviceContainer1}>
            <Grid item xs={12} md={6}>
                <Box>
                    <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: "primary.main", pb: 2}} variant="h6">Услуги</Typography>
                    <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold', color: "primary.main" }}>NXT2SP</Typography>
                    <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold', pb: 1}} variant="h4">IT решения и софтуер</Typography>
                    <Typography>
                        Ние предлагаме различни технологични решения и софтуер, които <br/>
                        подобряват резултатите и автоматизират процесите на вашия бизнес. 
                    </Typography>
                </Box>
                <Box sx={{ display: { xs: 'none', md: 'block', sm:'block', xm: 'block' }, py: 1 }}>
                    <CartServices1 />
                </Box>
                <Box sx={{ mt: 1, my: 2,  display: { xs: 'flex', md: 'none', sm:'none', xm: 'none' } }}>
                    <Swiper pagination={true} modules={[Pagination]} slidesPerView={1} spaceBetween={10} width={300}>
                        {items.map(({ icon, title, description }, index) => (
                            <SwiperSlide key={index}>
                                {({ isActive }) => (isActive ?
                                    <Card variant="outlined" sx={{ height: '360px', pt: 1, borderRadius: 5, borderColor: 'primary.main', backgroundColor: '#ebf5ff' }}>
                                        <CardContent sx={{ '&:last-child': { px: 2, py: 2, mt: 2 } }}>
                                            {icon}
                                            <Grid container alignItems="center" sx={{ my: 2 }}>
                                                <Grid item xs={12}>
                                                    <Typography sx={{ fontSize: 18, fontWeight: 'bold', my: 1 }} color="text.secondary">
                                                        {title}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ pb: 2, fontSize: 18 }} color="text.secondary">
                                                        {description}
                                                    </Typography>                                                    
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card> :
                                    <Card variant="outlined" sx={{ height: '360px', pt: 1}}>
                                    <CardContent sx={{ '&:last-child': { px: 2, py: 2, mt: 2 } }}>
                                        {icon}
                                        <Grid container alignItems="center" sx={{ my: 2 }}>
                                            <Grid item xs={12}>
                                                <Typography sx={{ fontSize: 18, fontWeight: 'bold', my: 1 }} color="text.secondary">
                                                    {title}
                                                </Typography>
                                                <Typography variant="body2" sx={{ pb: 2, fontSize: 18 }} color="text.secondary">
                                                    {description}
                                                </Typography>                                               
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>)}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <CodeBlock text={code} language='javascript' showLineNumbers={true} theme={dracula} /> 
            </Grid>
        </Grid>
    );
}