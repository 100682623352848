import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import styles from '../styles/styles';


export default function Contacts() {
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{py: {xxl: 10, xl: 6}, px: {xl: 0, md: 0, xs: 1}, backgroundColor: '#fafcff', borderBottom: '1px solid #d4d4d4' }}>
            <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: "primary.main", mt: 3, mb: 1 }}
                variant="h6">Започнете сега
            </Typography>
            <Typography align="center" sx={{fontFamily: 'Roboto', fontWeight: 'bold' }} variant="h4">Помагаме ви да постигате
                <Typography display={'inline'} sx={{ fontSize: 32, fontWeight: 'bold', color: "primary.main" }}>  резултати</Typography>
            </Typography>
            <Typography align="center" sx={styles.body2}>Ако искате да имате уебсайт или да увеличите онлайн присъствието на своята марка,<br />
                ние знаем как да го направим.
            </Typography>
            <Button size="large" component={Link} variant="contained" to="/contacts" color="primary" sx={{ height: 48, width: 256, borderRadius: 8, fontSize: 13, my: 3 }}>
                Свържете се с нас
            </Button>
        </Grid>
    );
}