 import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuLink from './MenuLink';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Logo } from "../svg/Logo.svg";
import styles from '../styles/styles';


export default function PreFooter() {
  return (
    <Grid container sx={styles.container1} paddingY={{xxl: 8, xl: 0}}>
      <Grid container item xl={4} md={3} sx={{my: 2, justifyContent:{ xl:'flex-start', xs: 'center'}}}>
        <SvgIcon viewBox='0 0 40 40' sx={{ width: '48px', height: '48px' }}>
          <Logo />
        </SvgIcon>
        <Typography sx={{py: 0.5,fontSize: 14, textAlign:{xl:'left', xs:'center'}}} variant="h6">Открийте света на технологичните иновации и реализирайте своите  идеи и проекти с нас.
        </Typography>
      </Grid>
      <Grid container item xl={2} md={2} xm={3} xs={6} direction="column" alignItems="flex-start" justifyContent='flex-start' sx={{pl: {xl: 1, sm: 4, xs: 0}}}>
          <Typography sx={styles.footerMain} variant="h6">NXT2SP</Typography>
          <Typography variant="h6"><MenuLink  sx={styles.footerText} to="/services">Изграждане на системи</MenuLink></Typography>
          <Typography variant="h6"><MenuLink  sx={styles.footerText} to="/services">Back-End платворми</MenuLink></Typography>
          <Typography variant="h6"><MenuLink  sx={styles.footerText} to="/services">Уеб Хостинг</MenuLink></Typography>
          <Typography variant="h6"><MenuLink  sx={styles.footerText} to="/services">Уеб приложение</MenuLink></Typography>          
      </Grid>
      <Grid container item xl={2} md={3}  xm={3} xs={6} direction="column" justifyContent='flex-start' alignItems="flex-start" sx={{pl: {xl: 3, sm: 8, xs: 5}}}>
          <Typography sx={styles.footerMain} variant="h6">NXTSP</Typography>
          <Typography variant="h6"><MenuLink  sx={styles.footerText} to="/services">Дизан на уеб сайт</MenuLink></Typography>
          <Typography variant="h6"><MenuLink  sx={styles.footerText} to="/services">Изработка на сайт</MenuLink></Typography>
          <Typography variant="h6"><MenuLink  sx={styles.footerText} to="/services">Техническа подръжка</MenuLink></Typography>
          <Typography variant="h6"><MenuLink  sx={styles.footerText} to="/services">SEO оптимизация</MenuLink></Typography>
          <Typography variant="h6"><MenuLink  sx={styles.footerText} to="/services">Анализ на уебсайт</MenuLink></Typography>
      </Grid>
      <Grid container item xl={2} md={2}  xm={3} xs={6} direction="column" justifyContent='flex-start' alignItems="flex-start" sx={{pl: {xl: 1, sm: 4, xm: 6, xs: 0}, py: {xl: 0, xm: 0, xs: 2}}}>
          <Typography sx={styles.footerMain} variant="h6">NEXT TO SPACE</Typography>
          <Typography variant="h6"><MenuLink  sx={styles.footerText} to="/services">Изготвяне на кампании</MenuLink></Typography>
          <Typography variant="h6"><MenuLink  sx={styles.footerText} to="/services">Социални мрежи</MenuLink></Typography>
          <Typography variant="h6"><MenuLink  sx={styles.footerText} to="/services">Копирайтинг</MenuLink></Typography>
          <Typography variant="h6"><MenuLink  sx={styles.footerText} to="/services">Видео маркетинг</MenuLink></Typography>          
      </Grid>
      <Grid container item xl={2} md={2}  xm={3} xs={6} direction="column" justifyContent='flex-start' alignItems="flex-start" sx={{pl: {xl: 3, sm: 8, xm: 6, xs: 5}, py: {xl: 0, xm: 0, xs: 2}}}>
          <Typography sx={styles.footerMain} variant="h6">ЗА КОМПАНИЯТА</Typography>
          <Typography variant="h6"><MenuLink  sx={styles.footerText} to="/aboutus">За нас</MenuLink></Typography>
          <Typography variant="h6"><MenuLink  sx={styles.footerText} to="/carreers">Кариери</MenuLink></Typography>
          <Typography variant="h6"><MenuLink  sx={styles.footerText} to="/contacts">Контакти</MenuLink></Typography>          
      </Grid>     
    </Grid>
  );
}