import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styles from '../../styles/styles';


export default function Description() {
  return (
    <Grid spacing={1} container sx={styles.template} paddingTop={14}>

      <Grid item xl={12} md={6} xs={12} overflow='hidden'>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', py: 2 }} variant="h4">Политика за поверителност</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">Полагаме грижа за личните ви данни</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold', py: 2 }} variant="h4">Информация за администратора на лични данни</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>Некст то Спейс ЕООД е дружество, регистрирано в Търговския регистър на Агенцията по вписванията
          с ЕИК: 207535071, e-mail: office@nxtsp.com, с тел.: +359 32 940 990.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">1. Основания и цели, за които използваме вашите лични данни</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>Ние обработваме вашите лични данни на следните основания:<br />
         <span style={{fontSize:18}}>&#8226;</span> Общите условия за използване на уебсайта;<br />
         <span style={{fontSize:18}}>&#8226;</span> Изрично съгласие от вас – целта се посочва за всеки конкретен случай;<br />
         <span style={{fontSize:18}}>&#8226;</span> При предвидено задължение по закон;<br />
          <br />
          В следващите параграфи ще намерите детайлна информация относно обработването на личните ви данни в зависимост от основанието, на което ги обработваме.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 20, fontWeight: 'bold' }} variant="h4">1.1 За изпълнение на договор</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>Ние обработваме личните ви данни за нуждите на използване на уебсайта съобразно с правилата на общите условия.<br />
          Цели на обработката (когато е относимо):<br />
          <span style={{fontSize:18}}>&#8226;</span>  установяване на самоличността ви;<br />
          <span style={{fontSize:18}}>&#8226;</span>  предоставяне на функционалностите на нашия уебсайт;<br />
          <br />
          На това основание обработваме единствено лични данни предоставени във връзка с подаденото запитване в контактната форма, като булстат на компанията, име и фамилия, телефон и имейл адрес.
          Данните, събрани на това основание изтриваме 2 години след прекратяване на договорното отношение, независимо дали поради изтичане срока на договора, разваляне или друго основание.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 20, fontWeight: 'bold' }} variant="h4">1.2 След ваше съгласие</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>Ние обработваме вашите лични данни на това основание само след изрично, недвусмислено и доброволно
          съгласие от ваша страна. Ние няма да предвиждаме каквито и да е неблагоприятни последици за вас, ако откажете обработването на личните данни.<br />
          <br />
          Съгласието е отделно основание за обработване на личните ви данни и целта на обработката е посочена в него, и не се покрива с целите, изброени в тази политика.
          Ако ни дадете съответното съгласие и до неговото оттегляне или прекратяване на всякакви договорни отношения с вас изготвяме подходящи за вас предложения за продукти/услуги,
          като извършваме детайлни анализи на ваши основни лични данни.<br />
          <br />
          <span style={{fontSize:18}}>&#8226;</span> Данни, които обработваме на това основание:<br />
          <br />
          На това основание можем да обработваме лични данни за целите на директния маркетинг, включително данни за потребление на уебсайта и данни за профили в социалните мрежи.<br />
          <br />
          <span style={{fontSize:18}}>&#8226;</span> Предоставяне на данни на трети лица:<br />
          <br />
          На това основание можем да предоставим вашите данни на маркетинг агенции, Facebook, Google или други подобни.<br />
          <br />
          <span style={{fontSize:18}}>&#8226;</span> Оттегляне на съгласие<br />
          <br />
          Предоставените съгласия могат да бъдат оттеглени по всяко време. Оттеглянето на съгласието няма отражение върху изпълнението на договорните задължения. Ако оттеглите съгласието си за 
          обработване на лични данни за някой или всички начини, описани по-горе, ние няма да използваме личните ви данни и информация за определените по-горе цели. Оттеглянето на съгласието не засяга 
          законосъобразността на обработването, основано на дадено съгласие преди неговото оттегляне. За да оттеглите даденото съгласие е необходимо само да използвате сайта ни или 
          просто данните ни за контакт.<br />
          <br />
          <span style={{fontSize:18}}>&#8226;</span> Кога заличаваме данните, събрани на това основание<br />
          <br />
          Данните, събрани на това основание, изтриваме при искане от ваша страна или 12 месеца след първоначалното им събиране.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">2. Как защитаваме вашите лични данни</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>За осигуряване на адекватна защита на данните на компанията и своите клиенти, 
          ние прилагаме всички необходими организационни и технически мерки, предвидени в Закона за защита на личните данни. Компанията е установила 
          правила за предотвратяване на злоупотреби и пробиви в сигурността, което подпомага процесите по опазване и обезпечаване сигурността на вашите данни.
          С цел максимална сигурност при обработка, пренос и съхранение на вашите данни, може да използваме допълнителни механизми за защита като криптиране, псевдонимизация и др.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">3. Права на потребителите</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>Всеки потребител на сайта се ползва с всички права за защита на личните данни съгласно българското 
          законодателство и правото на Европейския съюз. Потребителят може да упражни правата си чрез изпращане на съобщение до имейла ни. Всеки потребител има право на:
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2, wordWrap: "break-word" }}>
        <span style={{fontSize:18}}>&#8226;</span> Информираност (във връзка с обработването на личните му данни от администратора);<br />          
        <span style={{fontSize:18}}>&#8226;</span> Достъп до собствените си лични данни;<br />
        <span style={{fontSize:18}}>&#8226;</span> Коригиране (ако данните са неточни);<br />
        <span style={{fontSize:18}}>&#8226;</span> Изтриване на личните данни (право „да бъдеш забравен“);<br />
        <span style={{fontSize:18}}>&#8226;</span> Ограничаване на обработването от страна на администратора или обработващия лични данни;<br />
        <span style={{fontSize:18}}>&#8226;</span> Преносимост на личните данни между отделните администратори;<br />
        <span style={{fontSize:18}}>&#8226;</span> Възражение спрямо обработването на негови лични данни;<br />
        <span style={{fontSize:18}}>&#8226;</span> Субектът на данни има право и да не бъде обект на решение, основаващо се единствено на автоматизирано обработване, включващо профилиране, което поражда правни последствия за 
          субекта на данните или по подобен начин го засяга в значителна степен;<br />
          <span style={{fontSize:18}}>&#8226;</span> Право на защита по съдебен или административен ред, в случай, че правата на субекта на данни са били нарушени.<br />
          <span style={{fontSize:18}}>&#8226;</span> Потребителят може да поиска изтриване, ако е налице едно от следните условия:<br />
          <span style={{fontSize:18}}>&#8226;</span> Личните данни повече не са необходими за целите, за които са били събрани или обработвани по друг начин;<br />
          <span style={{fontSize:18}}>&#8226;</span> Потребителят оттегля своето съгласие, върху което се основава обработването на данните и няма друго правно основание за обработването;<br />
          <span style={{fontSize:18}}>&#8226;</span> Потребителят данните възразява срещу обработването и няма законни основания за обработването, които да имат преимущество;
          <span style={{fontSize:18}}>&#8226;</span> Личните данни са били обработвани незаконосъобразно;<br />
          <span style={{fontSize:18}}>&#8226;</span> Личните данни трябва да бъдат изтрити с цел спазването на правно задължение по правото на Съюза или правото на държава членка, което се прилага спрямо администратора;<br />
          <span style={{fontSize:18}}>&#8226;</span> Оспори точността на личните данни. В този случай ограничаването на обработването е за срок, който позволява на администратора да провери точността на личните данни;<br />
          <span style={{fontSize:18}}>&#8226;</span> Обработването е неправомерно, но потребителят не желае личните данни да бъдат изтрити, а изисква вместо това ограничаване на използването им;<br />
          <span style={{fontSize:18}}>&#8226;</span> Администраторът не се нуждае повече от личните данни за целите на обработването, но потребителят ги изисква за установяването, упражняването или защитата на правни претенции;<br />
          <span style={{fontSize:18}}>&#8226;</span> Възразява срещу обработването в очакване на проверка дали законните основания на администратора имат преимущество пред интересите на потребителя.<br />
          <span style={{fontSize:18}}>&#8226;</span> Потребителят има право да ограничи обработването на своите лични данни от страна на администратора, когато:<br />
          <span style={{fontSize:18}}>&#8226;</span> Оспори точността на личните данни. В този случай ограничаването на обработването е за срок, който позволява на администратора да провери точността на личните данни;<br />
          <span style={{fontSize:18}}>&#8226;</span> Обработването е неправомерно, но потребителят не желае личните данни да бъдат изтрити, а изисква вместо това ограничаване на използването им;<br />
          <span style={{fontSize:18}}>&#8226;</span> Администраторът не се нуждае повече от личните данни за целите на обработването, но потребителят ги изисква за установяването, упражняването или защитата на правни претенции;<br />
          <span style={{fontSize:18}}>&#8226;</span> Възразява срещу обработването в очакване на проверка дали законните основания на администратора имат преимущество пред интересите на потребителя.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">3.1 За изпълнение на договор</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>Потребителите имат право да възразят пред администратора срещу обработването на личните им данни. 
          Администраторът на лични данни е длъжен да прекрати обработването, освен ако не докаже, че съществуват убедителни законови основания за обработването, 
          които имат предимство пред интересите, правата и свободите на субекта на данни, или за установяването, упражняването или защитата на правни претенции.
          При възразяване срещу обработването на лични данни за целите на директния маркетинг обработването следва да се прекрати незабавно.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">3.2 Право на възражение</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>Потребителите имат право да възразят пред администратора срещу обработването на личните им данни. 
          Администраторът на лични данни е длъжен да прекрати обработването, освен ако не докаже, че съществуват убедителни законови основания за обработването, 
          които имат предимство пред интересите, правата и свободите на субекта на данни, или за установяването, упражняването или защитата на правни претенции. 
          При възразяване срещу обработването на лични данни за целите на директния маркетинг обработването следва да се прекрати незабавно.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">3.3 Жалба до надзорния орган</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>Всеки потребител има право да подаде жалба срещу незаконосъобразно обработване на 
          личните му данни до Комисия за защита на личните данни или до компетентния съд.
        </Typography>


      </Grid>

    </Grid>
  );
}    