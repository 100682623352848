import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import content from '../../content/aboutBoxContent';
import styles from '../../styles/styles';

const items = content;

export default function Main() {
    return (
        <Grid spacing={3} container sx={styles.template}>
            <Grid spacing={3} container sx={{ display: { xl: 'flex', xs: 'none', md: 'flex', xm:'flex' },  pl: 3 }}>
                {items.map(({ icon, title, description }, index) => (
                    <Grid key={index} item xl={4} xs={12} md={4} xm={6} >
                        <Card key={index} variant="outlined" sx={{borderColor: "primary.main", height: '236px', backgroundColor: '#fafcff', borderRadius: 5, ':hover': {
                                boxShadow: '0px -1px 6px 0px rgba(46, 151, 255, 0.18), 0px 1px 6px 0px rgba(46, 151, 255, 0.18), 1px 0px 6px 0px rgba(46, 151, 255, 0.18), -1px 0px 6px 0px rgba(46, 151, 255, 0.18)',
                                borderColor: "primary.main", backgroundColor: '#fafcff'
                            }
                        }}>
                            <CardContent sx={{ '&:last-child': { py: 3, px: 3 } }}>
                                {icon}
                                <Grid container alignItems="center">
                                    <Grid item xs={12}>
                                        <Typography sx={{ fontSize: 14, fontWeight: '600', mt: 3, mb: 1 }} color="black">
                                            {title}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Box sx={{ mt: 1, my: 2, mx: 2, display: { xs: 'flex', xm:'none', md: 'none' } }} overflow='hidden'>
                <Swiper pagination={true} modules={[Pagination]} slidesPerView={1} spaceBetween={5} width={252}>
                    {items.map(({ icon, title, description }, index) => (
                        <SwiperSlide key={index}>
                            {({ isActive }) => (isActive ?
                                <Card variant="outlined" sx={{ height: '300px', pt: 1, borderRadius: 5, borderColor: 'primary.main', backgroundColor: '#ebf5ff' }}>
                                    <CardContent sx={{ '&:last-child': { py: 3, px: 3 } }}>
                                        {icon}
                                        <Grid container alignItems="center">
                                            <Grid item xs={12}>
                                                <Typography sx={{ fontSize: 14, fontWeight: '600', mt: 3, mb: 1 }} color="black">
                                                    {title}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {description}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card> :
                                <Card variant="outlined" sx={{ height: '300px', pt: 1 }}>
                                    <CardContent sx={{ '&:last-child': { py: 3, px: 3 } }}>
                                        {icon}
                                        <Grid container alignItems="center">
                                            <Grid item xs={12}>
                                                <Typography sx={{ fontSize: 14, fontWeight: '600', mt: 3, mb: 1 }} color="black">
                                                    {title}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {description}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>)}
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Box>
        </Grid>
    );
}    