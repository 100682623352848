import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as WordPress } from "../svg/wordpress.svg";
import { ReactComponent as Figma } from "../svg/figma.svg";
import { ReactComponent as Adobexd } from "../svg/adobexd-32x32.svg";
import { ReactComponent as Illustrator } from "../svg/illustrator.svg";

const items = [    
    {
        icon:  <SvgIcon viewBox='0 0 32 32' sx={{ width: '32px', height: '32px', mx: 2}}><WordPress/></SvgIcon>,
        title: 'WordPress',
        description:'Гъвкава и лесна за употреба платформа за уебсайтове.'        
    },
    {
        icon: <SvgIcon viewBox='0 0 32 32' sx={{ width: '32px', height: '32px', mx: 2}}><Figma/></SvgIcon>,
        title: 'Figma',
        description: 'Инструмент за създаване на колаборативен UI/UX дизайн.'
        
    },
    {
        icon: <SvgIcon viewBox='0 0 32 32' sx={{ width: '32px', height: '32px', mx: 2}}><Adobexd/></SvgIcon>,
        title: 'Adobe XD',
        description: 'Мощен софтуер за изработка на уеб дизайн.',        
    },
    {
        icon: <SvgIcon viewBox='0 0 32 32' sx={{ width: '32px', height: '32px', mx: 2}}><Illustrator/></SvgIcon>,
        title: 'Illustrator',
        description: 'Векторно базиран софтуер за създаване на лога, икони и др.',        
    },
];

export default items;