import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import styles from '../../styles/styles';


export default function DescriptionTwoFront() {
  return (
    <Grid spacing={1} container sx={styles.template} borderBottom='1px solid #d4d4d4'>

      <Grid item xl={6} md={6} xs={12} overflow='hidden'>

        <Box sx={{ pt: 6 }} overflow='hidden'>
          <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">Изисквания</Typography>
          <List sx={{ listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Опит в разработването с Laravel и Blade шаблони</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Професионално владеене на CSS и Sass</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Практически опит с React и JavaScript</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Знания за NodeJS и Webpack</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Умения за работа с API, включително уверено използване на Postman или подобни инструменти</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Опит с datatables.net.</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Опит с MUI (Material-UI) ще се счита за голямо предимство</Typography></ListItem>
          </List>
        </Box>

      </Grid>

      <Grid item xl={6} md={6} xs={12} overflow='hidden'>

        <Box sx={{ pt: 6, pl: {xl: 3, xs: 0} }}>
          <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">Какво предлагаме</Typography>
          <List sx={{ listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Динамична и интересна работна среда;</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Възможност за използване на най-новите технологии в реални проекти;</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Сътрудничество с висококвалифицирани и приятелски настроени колеги;</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Разбиране на принципите на реактивния дизайн за различни устройства;</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Специален корпоративен бонус за рожден ден;</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Офис предимства кафе, безалкохолни напитки.</Typography></ListItem>
          </List>
        </Box>

      </Grid>


    </Grid>
  );
}    