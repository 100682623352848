import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from '../../styles/styles';


export default function Information() {
  return (
    <Grid spacing={3} container sx={styles.templateBorder} paddingY={9} justifyContent="flex-start" alignItems="left">

      <Grid item xl={6} md={12} xs={12}>
        <Box>
          <Typography sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', letterSpacing: 1, py: 1 }} variant="h4">Често задавани въпроси</Typography>
        </Box>

        <Box sx={{ my: 2 }}>
          <Accordion>
            <AccordionSummary expandIcon={<KeyboardArrowDownIcon style={{ color: 'blue' }}/>} aria-controls="panel1-content" id="panel1-header">
              <Typography sx={{ fontSize: 14 }}>Има ли възможност за работа от вкъщи или хибриден начин на работа?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontSize: 14 }}>Не предоставяме възможност за работа от вкъщи и хибриден начин на работа.</Typography>
            </AccordionDetails>
          </Accordion>

        </Box>

        <Box sx={{ my: 2 }}>
          <Accordion>
            <AccordionSummary expandIcon={<KeyboardArrowDownIcon style={{ color: 'blue' }}/>} aria-controls="panel1-content" id="panel1-header">
              <Typography sx={{ fontSize: 14 }}>Какви качества търсим в потенциалните кандидати за работа?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontSize: 14 }}>Търсим кандидати със силни комуникативни умения, адаптивност, работа в екип, проактивност и желание за развитие.</Typography>
            </AccordionDetails>
          </Accordion>
        </Box>       
      </Grid>

      <Grid item xl={6} md={12} xs={12} sx={{ mt: 7 }}>

        <Box sx={{ my: 2 }}>
          <Accordion>
            <AccordionSummary expandIcon={<KeyboardArrowDownIcon style={{ color: 'blue' }}/>} aria-controls="panel1-content" id="panel1-header" >
              <Typography sx={{ fontSize: 14 }}>Какъв е процесът на кандидатстване за работа?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontSize: 14 }}>Кандидатстването при нас включва изпращане на CV и портфолио, последвано от интервю за запознаване и одобряване на кандидата.</Typography>
            </AccordionDetails>
          </Accordion>

        </Box>

        <Box sx={{ my: 2 }}>
          <Accordion>
            <AccordionSummary expandIcon={<KeyboardArrowDownIcon style={{ color: 'blue' }}/>} aria-controls="panel1-content" id="panel1-header">
              <Typography sx={{ fontSize: 14 }}>Какво представлява типичният работен ден при нас?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontSize: 14 }}>Типичният работен ден включва срещи за планиране на проекти, колаборация с екипа, разработка на решения и комуникация с клиенти.</Typography>
            </AccordionDetails>
          </Accordion>
        </Box>

      </Grid>

    </Grid>
  );
}    