import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CartServices from './CartServices';
import Typography from '@mui/material/Typography';
import content from '../content/ServicesContent'
import styles from '../styles/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const items = content;

export default function Services() {
    return (
        <Grid container sx={styles.container2}>
            <Grid item xs={12} md={6}>
                <Box>
                    <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: "primary.main" }} variant="h6">Услуги</Typography>
                    <Typography sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', py: 1 }} variant="h4">Защо ние се
                        <Typography display={'inline'} sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', color: "primary.main" }}> отличаваме</Typography>
                    </Typography>
                    <Typography>
                        Вярваме, че сътрудничеството с нашите клиенти е ключът към отключването на истинския бизнес потенциал.
                    </Typography>
                </Box>
                <Box sx={{ display: { xs: 'none', md: 'block', sm:'block', xm:'block' }, py: 2 }}>
                    <CartServices />
                </Box>
                <Box sx={{ mt: 1, my: 2,  display: { xs: 'flex', md: 'none', sm: 'none', xm:'none' } }}>
                    <Swiper pagination={true} modules={[Pagination]} slidesPerView={1} spaceBetween={10} width={300}>
                        {items.map(({ icon, title, description }, index) => (
                            <SwiperSlide key={index}>
                                {({ isActive }) => (isActive ?
                                    <Card variant="outlined" sx={{ height: '360px', pt: 1, borderRadius: 5, borderColor: 'primary.main', backgroundColor: '#ebf5ff' }}>
                                        <CardContent sx={{ '&:last-child': { px: 2, py: 2, mt: 2 } }}>
                                            {icon}
                                            <Grid container alignItems="center" sx={{ my: 2 }}>
                                                <Grid item xs={12}>
                                                    <Typography sx={{ fontSize: 18, fontWeight: 'bold', my: 1 }} color="text.secondary">
                                                        {title}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ pb: 2, fontSize: 18 }} color="text.secondary">
                                                        {description}
                                                    </Typography>
                                                    <Typography sx={{ fontSize: 18, fontWeight: 600 }} color="primary.main">Научи повече</Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card> :
                                    <Card variant="outlined" sx={{ height: '360px', pt: 1}}>
                                    <CardContent sx={{ '&:last-child': { px: 2, py: 2, mt: 2 } }}>
                                        {icon}
                                        <Grid container alignItems="center" sx={{ my: 2 }}>
                                            <Grid item xs={12}>
                                                <Typography sx={{ fontSize: 18, fontWeight: 'bold', my: 1 }} color="text.secondary">
                                                    {title}
                                                </Typography>
                                                <Typography variant="body2" sx={{ pb: 2, fontSize: 18 }} color="text.secondary">
                                                    {description}
                                                </Typography>
                                                <Typography sx={{ fontSize: 18, fontWeight: 600 }} color="primary.main">Научи повече</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>)}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box component="img" sx={{ pl: { xl: 2, xs: 0 }, width: '100%', height: '100%' }} alt="The img." src="/marketing-service-photo-lg-323KB.jpg" />
            </Grid>
        </Grid>
    );
}