import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import content from '../content/ServicesContent';
import styles from '../styles/styles';

const items = content;

export default function CartServices() {  
  return (
    items.map(({ icon, title, description }, index) => (
      <Card key={index} variant="outlined" sx={styles.mainCard}>
        <CardContent sx={{ '&:last-child': { px: 0, py: 2.5 } }}>
          <Grid container alignItems="center">
            <Grid item xs={2}>
              {icon}
            </Grid>
            <Grid item xs={9}>
              <Typography sx={{ fontSize: 14, fontWeight: 600 }} color="black" gutterBottom>
                {title}
              </Typography>
              <Typography variant="body2" sx={{ mb: 0.5 }}>
                {description}
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 600 }} color="primary.main">Научи повече</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))
  );
}