import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import styles from '../../styles/styles';


export default function DescriptionOneSeo() {
  return (
    <Grid spacing={1} container sx={styles.template} paddingTop={14}>

      <Grid item xl={8} md={6} xs={12} overflow='hidden'>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold' }} variant="h4">PR Специалист по Дигитален маркетинг и реклама</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 18, py: 2 }}>В този вълнуващ момент от нашето развитие, търсим амбициозен и предприемачески 
        настроен PR Специалист по Дигитален Маркетинг и Реклама. NXTSP предлага уникална възможност да станете част от водеща компания в рекламната индустрия, да работите с иновативни технологии и да допринесете за развитието на дейностите които предлагаме..
        </Typography>       

        <Box sx={{ pt: 6 }}>
          <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">Детайли за позицията</Typography>
          <List sx={{ listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}><b>Локация:</b> гр. Пловдив, ул. Рогошко шосе №36</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}><b>Работно време: </b>Осемчасов работен ден - от понеделник до петък; 9:00 до 18:00</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}><b>Вид на работата: </b>Постоянна работа</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}><b>Години опит: </b>от 3 до 10+</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}><b>Отпуск: </b>20 дни</Typography></ListItem>
          </List>
        </Box>

        <Box sx={{ pt: 6 }} overflow='hidden'>
          <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">Отговорности</Typography>
          <List sx={{ listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Разработване и изпълнение на интегрирани стратегии за дигитален маркетинг.</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Създаване на визуално привлекателно съдържание за различни дигитални канали.</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Планиране и управление на маркетингови и рекламни кампании във водещи платформи като Google, Facebook, Instagram, LinkedIn и TikTok.</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Мониторинг и оптимизация на рекламни кампании за гарантиране на оптимален рекламен доход (ROAS).</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Управление на имейл маркетинг и автоматизирани маркетингови процеси.</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Анализиране на резултатите от кампаниите с помощта на аналитични инструменти като Google Analytics.</Typography></ListItem>             
          </List>
        </Box>

      </Grid>

    </Grid>
  );
}    