import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PlaceIcon from '@mui/icons-material/Place';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import styles from '../../styles/styles';


export default function PositionTwo() {
  return (
    <Grid spacing={1} container sx={styles.template} paddingY={{ xl: 3, xs: 0 }} borderBottom='1px solid #d4d4d4'>

      <Grid item xl={6} md={6} xm={8} xs={12}>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 26, fontWeight: 'bold', color: "primary.main", pt: 5 }} variant="h6">NXT2SP
          <Typography display={'inline'} sx={{ fontFamily: 'Roboto', fontSize: 26, fontWeight: 'bold', color: "black", pb: 1 }}> - Уеб дизайн и изработка</Typography>
        </Typography>

        <Box sx={{ py: 2 }}>
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: "black", pb: 1 }}>WordPress Developer</Typography>
          <Typography sx={{ fontSize: 16, color: "black" }}>Ще създавате уебсайтове, онлайн магазини с платформата WordPress.</Typography>
          <Button endIcon={<KeyboardArrowRightIcon />} variant="outlined" to="/jobs" color="primary" sx={{ '&:hover': { backgroundColor: 'primary.main', color: 'white' }, size: 'small', border: 2, borderRadius: 8, fontWeight:'bold', fontSize: 12, m: 2, p: 1.5, backgroundColor: '#fafcff', display: { xl: 'none', xm:'none', xs: 'flex' } }}>Научи повече</Button>
        </Box>

        <Box sx={{ py: 2 }}>
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: "black", pb: 1 }}>UI/UX Designer</Typography>
          <Typography sx={{ fontSize: 16, color: "black" }}>Ще се занимавате с дизайна на интерактивни преживявания за потребителите, съчетавайки естетика и функционалност.</Typography>
          <Button endIcon={<KeyboardArrowRightIcon />} variant="outlined" to="/jobs" color="primary" sx={{ '&:hover': { backgroundColor: 'primary.main', color: 'white' }, size: 'small', border: 2, borderRadius: 8, fontWeight:'bold', fontSize: 12, m: 2, p: 1.5, backgroundColor: '#fafcff', display: { xl: 'none', xm:'none', xs: 'flex' } }}>Научи повече</Button>
        </Box>

      </Grid>

      <Grid item xl={4} md={3} xs={12} justifyContent="center" alignItems="center" sx={{ mt: 12, display: { xl: 'inline', xs: 'none' } }}>

        <Box display='flex' sx={{ py: 2, pl: { xl: 15, xs: 0 } }}>
          <PlaceIcon color="primary" fontSize="small" />
          <Typography sx={{ fontSize: 14, color: "black", px: 1 }}>гр. Пловдив.</Typography>
        </Box>
        <Box display='flex' sx={{ pb: 3, pl: { xl: 15, xs: 0 } }}>
          <WatchLaterIcon color="primary" fontSize="small" />
          <Typography sx={{ fontSize: 14, color: "black", px: 1 }}>Пълно работно време</Typography>
        </Box>

        <Box display='flex' sx={{ py: 2, pl: { xl: 15, xs: 0 } }}>
          <PlaceIcon color="primary" fontSize="small" />
          <Typography sx={{ fontSize: 14, color: "black", px: 1 }}>гр. Пловдив.</Typography>
        </Box>
        <Box display='flex' sx={{ pl: { xl: 15, xs: 0 } }}>
          <WatchLaterIcon color="primary" fontSize="small" />
          <Typography sx={{ fontSize: 14, color: "black", px: 1 }}>Пълно работно време</Typography>
        </Box>

      </Grid>


      <Grid item xl={2} md={3} xm={4} xs={6} sx={{ mt: { xl: 10, xm: 8, xs: 0 } }}>
        <Box sx={{ py: 3 }}>
          <Button endIcon={<KeyboardArrowRightIcon />} component={Link} variant="raised" to="/" color="primary"
            sx={{ '&:hover': { backgroundColor: 'primary.main', color: 'white' }, size: 'small', border: 2, borderRadius: 8, fontSize: 12, color: 'primary.main', fontWeight: 'bold', m: 2, p: 1, backgroundColor: '#fafcff', borderColor: 'primary.main', display: { xl: 'flex', xm:'flex', xs: 'none' } }}>Научи повече
          </Button>
        </Box>
        <Box sx={{ py: 1 }}>
          <Button endIcon={<KeyboardArrowRightIcon />} component={Link} variant="raised" to="/" color="primary"
            sx={{ '&:hover': { backgroundColor: 'primary.main', color: 'white' }, size: 'small', border: 2, borderRadius: 8, fontSize: 12, color: 'primary.main', fontWeight: 'bold', m: 2, p: 1, backgroundColor: '#fafcff', borderColor: 'primary.main', display: { xl: 'flex', xm:'flex', xs: 'none' } }}>Научи повече
          </Button>
        </Box>
      </Grid>

    </Grid>
  );
}    