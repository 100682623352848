import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styles from '../../styles/styles';


export default function Description() {
  return (
    <Grid spacing={1} container sx={styles.template} paddingTop={14}>

      <Grid item xl={12} md={6} xs={12} overflow='hidden'>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', py: 2 }} variant="h4">Политика за „бисквитки“</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">Използване на „бисквитки“</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>„Бисквитките“ са кратки текстови файлове или малки пакети от информация,
          които се съхраняват чрез интернет браузъра на вашето крайно устройство (компютър, таблет, лаптоп или мобилен телефон),
          когато посещавате различни сайтове и страници в интернет. Основната цел на „бисквитките“ е да правят потребителя разпознаваем,
          когато той се завръща отново на уебсайта. Някои „бисквитки“ имат и по-специфично приложение, като например да запаметяват
          потребителското поведение на сайта и да улесняват потребителя при ползването на уебсайта.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">1. Как се използват „бисквитки“ на този уебсайт?</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>Ние използваме „бисквитки“ на този Уебсайт предимно с цел улесняване на ползваемостта на
          сайта, подобряване на неговата работа и съхраняване на информация за потребителското поведение. При този процес не се съхраняват никакви лични данни,
          т.е. чрез “бисквитките“ на сайта не може да Ви идентифицираме като личност, поради което спрямо събирането на тази информация не се прилага Закона за защита
          на личните данни. Събраната информация от „бисквитки“ обикновено се използва в обобщен вид с цел анализ на потребителското поведение на Уебсайта, което ни
          позволява да подобряваме функционалността на сайта, потребителските пътеки и ползваното съдържание. Възможно е бисквитки да бъдат използвани и за целите на
          директния маркетинг, а именно за представяне на електронни търговски съобщения към потребителя извън Уебсайта.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold', py: 2 }} variant="h4">2. Какви „бисквитки“ се използват на този уебсайт?</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 20, fontWeight: 'bold' }} variant="h4">2.1 Сесийни „бисквитки“</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>Този тип „бисквитки“ ви улеснява в ползването на сайта, като те съхраняват информация временно,
          само в рамките на сесията на използвания браузер. Обикновено информацията, която се съхранява чрез тях, е какви стоки или услуги сте добавили в количката,
          кои страници на сайта сте посетили и как сте стигнали до дадена информация. Тези „бисквитки“ не събират информация от вашето крайно устройство
          и се изтриват автоматично, когато напуснете уебсайта или прекратите сесията на вашия браузър.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 20, fontWeight: 'bold' }} variant="h4">2.2 Постоянни "бисквитки"</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>Те ни дават възможност да съхраняваме конкретна информация за сърфирането,
          като например анализиране посещенията на сайта, каквие сте достигнали до уебсайта, какви страници сте прегледали, какви опции сте избрали,
          както и накъде сте се насочили през този уебсайт. Проследяването на тази информация, ни дава възможност да правим подобрения на уебсайта,
          включително да коригираме грешки и да разширяваме съдържанието. Срокът на съхранение на този вид “бисквитки” варира според конкретното им предназначение.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 20, fontWeight: 'bold' }} variant="h4">2.3 "Бисквитки" на трета страна</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>Възможно е на уебсайта да има препратки към други сайтове или вградено
          съдържание от други сайтове, например от Facebook, Instagram, LinkedIn, уебсайтове на партньори. Възможно е при посещаването на тези
          сайтове или отварянето на съдържанието от тях, да се складират на Вашето крайно устройство „бисквитки“ от тези уебсайтове.
          Именно тези „бисквитки“ се дефинират като „бисквитки на трети страни“, като нямаме контрол върху генерирането и управлението на
          тези „бисквитки“. За това Ви съветваме да потърсите информация за тях и за начина им на управление на уебсайтовете на съответните трети страни.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 20, fontWeight: 'bold' }} variant="h4">3. Как мога да управлявам използването на „бисквитки“ от този уебсайт?</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>Всички браузъри позволяват управлението на „бисквитки“ от специално създадена за целта папка на вашия браузър.
          Можете да блокирате получаването на „бисквитки“, да изтриете всички или част от тях или да зададете Вашите настройки за предпочитания по
          отношение на използването на „бисквитки“ преди да инициирате посещение на сайта ни. Имайте предвид, че изтриването или блокирането на „бисквитки“ може да
          повлияе неблагоприятно върху функциите на нашия уебсайт, а оттам и върху вашето потребителско преживяване на него.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 20, fontWeight: 'bold' }} variant="h4">3.1 Изключване или блокиране на „бисквитки“</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>Контролирането, изключването или блокирането на „бисквитките“ се управлява от настройките на
          вашия браузер. Имайте предвид, че пълната забрана на използването на всички „бисквитки“, може да рефлектира върху функционалното представяне на сайта,
          неговата ефективност и достъпването на определена информация.
        </Typography>


      </Grid>

    </Grid>
  );
}    