import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Space } from "../../svg/blue-rocket-illustration-468x226.svg";
import styles from '../../styles/styles';


export default function Main() {
  return (
    <Grid spacing={1} container sx={styles.template} paddingY={9}>

      <Grid item xl={6} md={6} xs={12}>
        <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: "primary.main", pb: 2 }} variant="h6">Принципи</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', letterSpacing: 3, color: "primary.main" }}>Принципите,</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold' }} variant="h4">които ни водят към </Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', pb: 1 }} variant="h4">неограничените възможности </Typography>
        <Typography>Нашите принципи се прилагат всеки ден в работата ни, било то при обсъждане на идеи за нови проекти или при вземане
          на най-доброто решение за разрешаване на даден проблем.
        </Typography>
      </Grid>

      <Grid container item xl={6} md={6} xs={12} alignItems='center' justifyContent='flex-end'>
        <SvgIcon viewBox='0 0 468 226' sx={{ width: '100%', height: 'auto'}}>
          <Space />
        </SvgIcon>
      </Grid>

    </Grid>
  );
}    