import * as React from 'react';
import AppNavBar from '../../components/AppNavBar';
import Main from './Main';
import Info from './Info'
import Questions from './Questions';
import Contacts from '../../components/Contacts';
import Container from '@mui/material/Container';
import PreFooter from '../../components/PreFooter';
import BottomNavBar from '../../components/BottomNavBar';
import PositionOne from './PositionOne';
import PositionTwo from './PositionTwo';
import PositionThree from './Position Three';

export default function CarreersPage() {
  return (
    <Container disableGutters maxWidth="auto">
      <AppNavBar />
      <Main/>
      <Info/>
      <PositionOne/>
      <PositionTwo/>
      <PositionThree/>      
      <Questions/>      
      <Contacts />
      <PreFooter />
      <BottomNavBar />
    </Container>
  );
}
