import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import EmailIcon from '@mui/icons-material/Email';
import { AppBar } from '@mui/material';
import CustomAvatar from '../components/CustomAvatar';
import DrawerMenu from '../components/DrawerMenu';
import styles from '../styles/styles';
import { ReactComponent as Logo } from "../svg/Logo.svg";
import MenuLink from './MenuLink';

export default function AppNavBar() {
    return (
        <AppBar position="sticky" sx={{ background: 'rgba(254, 254, 254, 0.85)', boxShadow: 'none', backdropFilter:"blur(10px)" }}>
            <Container disableGutters maxWidth="xl">
                <Toolbar sx={{ borderBottom: 0.2, borderColor: 'divider', px: { xl: 24, md: 8, sm: 1, xs: 1 }, py: 2 }}>
                    <SvgIcon onClick={event =>  window.location.href='/'} viewBox='0 0 40 40' sx={{ cursor: 'pointer', fontSize: 40 }}>
                        <Logo />
                    </SvgIcon>
                    <Box sx={{ flexGrow: 1, alignItems: 'center', px: 6 }}>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 3, alignItems: 'center' }}>
                            <Typography sx={styles.menu}><MenuLink to="/">Начало</MenuLink></Typography>
                            <Typography sx={styles.menu}><MenuLink to="/services">Услуги</MenuLink></Typography>
                            <Typography sx={styles.menu}><MenuLink to="/aboutus">За нас</MenuLink></Typography>
                            <Typography sx={styles.menu}><MenuLink to="/contacts">Контакти</MenuLink></Typography>
                        </Box>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
                        <CustomAvatar><EmailIcon sx={{cursor: 'pointer', fontSize: 24}} onClick={event =>  window.location.href='/contacts'} color="primary"/></CustomAvatar>
                    </Box>
                    < DrawerMenu />
                </Toolbar>
            </Container>
        </AppBar>
    );
}