import React from 'react';
import { styled } from '@mui/material/styles';
import { NavLink as NavLinkBase } from "react-router-dom";

const MenuLink = styled((props) => (
  <NavLinkBase {...props} end={props.to === "/"} />))(({ theme }) => ({
    textDecoration: "none",
    fontSize: "small",
    color: "#555E6B",
    fontStyle: 'normal',
    fontWeight: 600,
    "&.active": {
      fontSize: "small",
      color: "#2e97ff",
      fontStyle: 'normal',
      fontWeight: 600,
    }
  }));

export default MenuLink;