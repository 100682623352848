import * as React from 'react';
import AppNavBar from '../../components/AppNavBar';
import ServiceOne from './ServiceOne';
import ServiceTwo from './ServiceTwo';
import ServiceThree from './ServiceThree';
import Information from './Information';
import HowServices from './HowServices';
import Contacts from '../../components/Contacts';
import Container from '@mui/material/Container';
import PreFooter from '../../components/PreFooter';
import BottomNavBar from '../../components/BottomNavBar';

export default function ServicePage() {
  return (
    <Container disableGutters maxWidth="auto">
      <AppNavBar />
      <ServiceOne />
      <ServiceTwo />
      <ServiceThree />
      <Information />
      <HowServices />
      <Contacts />
      <PreFooter />
      <BottomNavBar />
    </Container>
  );
}
