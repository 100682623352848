import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Jquery } from "../svg/jquery.svg";
import { ReactComponent as PHP } from "../svg/php.svg";
import { ReactComponent as SASS } from "../svg/sass.svg";
import { ReactComponent as MySQL } from "../svg/mysql.svg";
import { ReactComponent as Photoshop } from "../svg/photoshop.svg";
import { ReactComponent as Canva } from "../svg/canva.svg";
import { ReactComponent as DaVinci } from "../svg/davinci.svg";

const items = [    
    {
        icon:  <SvgIcon viewBox='0 0 32 32' sx={{ width: '32px', height: '32px', mx: 2}}><Photoshop/></SvgIcon>,
        title: 'Photoshop',
        description:'Редактиране на изображения и ретуширане на снимки.'        
    },
    {
        icon: <SvgIcon viewBox='0 0 32 32' sx={{ width: '32px', height: '32px', mx: 2}}><Canva/></SvgIcon>,
        title: 'Canva',
        description: 'Интуитивен онлайн софтуер за създаване на дизайн.'
        
    },
    {
        icon: <SvgIcon viewBox='0 0 32 32' sx={{ width: '32px', height: '32px', mx: 2}}><DaVinci/></SvgIcon>,
        title: 'DaVinci Resolve',
        description: 'Професионален видео редактор от ново поколение.',        
    }
];

export default items;