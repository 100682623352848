import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styles from '../../styles/styles';


export default function Description() {
  return (
    <Grid spacing={1} container sx={styles.template} paddingTop={14}>

      <Grid item xl={12} md={6} xs={12} overflow='hidden'>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">Общи условия</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>Нашето дружество – NextToSpace, регистрирано в българския
          Търговски регистър при Агенцията по вписванията, с ЕИК: 207535071, със седалище и адрес на управление: Република България,
          област Пловдив, гр. Пловдив, п.к. 4003, ул. Рогошко шосе №36, телефон: +359 32 940 990, електронна поща: office@nxtsp.com.
          Настоящите общи условия уреждат условията за използване на nxtsp.com. Чрез използването на уебсайта потребителят
          се съгласява с настоящите общи условия. Ако не сте съгласни със същите, следва да се въздържате от използване на уебсайта.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">1. Интелектуална собственост</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>1.1 Всички права върху интелектуалната собственост по отношение на
          информационните ресурси и съдържанието на уебсайта се упражняват от Оператора. Същите са защитени от Закона за авторското право
          и сродните му права и всяко използване на съдържание от Уебсайта без съгласието на Оператора е нарушение на закона, от което може да
          последва гражданска, административно наказателна или наказателна отговорност.
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>1.2 При копиране или възпроизвеждане на информация вън от допустимото,
          както и при всяко друго нарушение на правата на интелектуална
          собственост върху ресурсите на Оператора, Операторът има правото да претендира обезщетение и за претърпените преки и косвени вреди в пълен размер.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">2. Ограничаване на отговорността</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>2.1 Операторът предприема необходимите мерки, за да поддържа съдържанието на
          уебсайта актуално, но не носи отговорност за действителната актуалност на същото. Във връзка с това операторът не носи отговорност за вреди,
          в това число преки и косвени вреди, включително пропуснати ползи, които потребител на уебсайта би понесъл при каквито и да е обстоятелства,
          свързани с публикуваното съдържание на уебсайта и достъпа до същия. Използвайки уебсайта, потребителите поемат всички рискове, свързани
          с това, включително всеки риск за крайни потребителски устройства, софтуер или данни на потребителите..
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>1.2 При копиране или възпроизвеждане на информация вън от допустимото,
          както и при всяко друго нарушение на правата на интелектуална собственост върху ресурсите на Оператора, Операторът има правото да
          претендира обезщетение и за претърпените преки и косвени вреди в пълен размер.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">2. Ограничаване на отговорността</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>2.1 Операторът предприема необходимите мерки, за да поддържа съдържанието на
          уебсайта актуално, но не носи отговорност за действителната актуалност на същото. Във връзка с това операторът не носи отговорност за вреди,
          в това число преки и косвени вреди, включително пропуснати ползи, които потребител на уебсайта би понесъл при каквито и да е обстоятелства,
          свързани с публикуваното съдържание на уебсайта и достъпа до същия. Използвайки уебсайта, потребителите поемат всички рискове, свързани
          с това, включително всеки риск за крайни потребителски устройства, софтуер или данни на потребителите.
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>2.2 Доколкото в съдържанието на уебсайта се съдържат препратки към съдържание,
          позиционирано извън същия потребителят е запознат с факта, че операторът няма контрол върху същото и не носи отговорност за последиците от
          активиране на препратки, водещи извън уебсайта. Операторът не носи отговорност за злонамерени действия от страна на трети лица,
          обект на които би могъл да стане потребителя, при използване на уебсайта. Потребителят използва уебсайта на своя отговорност.
        </Typography>


        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">3. Регистрация на потребители</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>3.1 Операторът запазва правото си да предоставя част от съдържанието на уебсайта
          единствено на потребители, които са регистрирали свой потребителски профил на уебсайта. Съответното съдържание, ограничено единствено
          до регистрирани потребители, следва във всеки един момент да бъде обозначено като такова от страна на оператора.
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>3.2 При създаване на потребителски профил потребителят се съгласява, че
          операторът ще обработва неговите лични данни съобразно с правилата на Политиката за защита на лични данни, публикувана на уебсайта.
        </Typography>


        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">4. Използване на уебсайта</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>4.1 Доколкото операторът е предвидил възможност за потребители да качват
          съдържание в уебсайта, същите следва да не качват клеветническо, нецензурирано, обидно, порнографско или по друг начин незаконно или
          противоречащо на морала съдържание.
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>4.2 Потребителите следва да използват уебсайта добросъвестно,
          в това число да не предприемат злонамерени действия, които да могат да се отразят на достъпността на съдържанието за трети лица или използването на уебсайта изобщо.
        </Typography>

        <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">5. Други</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>5.1 Операторът запазва правото си да изменя настоящите общи условия по свое усмотрение
          като във всеки един момент актуалните условия за ползване на уебсайта ще бъдат публикувани на същия.
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 16, py: 2 }}>5.2 По всички неуредени от настоящите Общи условия въпроси се прилагат разпоредбите
          на действащото законодателство на Република България.
        </Typography>

      </Grid>

    </Grid>
  );
}    