import * as React from 'react';
import CartBox from './CartBox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import styles from '../styles/styles';
import { ReactComponent as Next } from "../svg/nextlogistic-90x51.svg";
import { ReactComponent as Ferry } from "../svg/truckferry-123x53.svg";
import { ReactComponent as Dauto } from "../svg/drauto-58x69.svg";
import { ReactComponent as Pimk } from "../svg/pimk-113x45.svg";


export default function CardMainBox() {
    return (
        <Grid spacing={3} container sx={styles.container1}>
            <Grid item md={12} xs={12}>                
                <Typography sx={styles.mainBold} variant="h6">Защо да изберете нас</Typography>
                <Typography variant="h4" sx={{ fontFamily: 'Roboto' , fontSize: 32, fontWeight: 'bold', color: "primary", mt: 1 }}>Едно
                    <Typography display={'inline'} sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', color: "primary.main" }}> вълнуващо изживяване</Typography>
                    <Typography ariant="h4" sx={{ display:{xl: 'none', xs:'inline'}, fontFamily: 'Roboto' , fontSize: 32, fontWeight: 'bold', color: "primary", mb: 2}}> за вас и вашите потребители</Typography>
                </Typography>
                <Typography variant="h4" sx={{ display:{xl: 'flex', xs:'none'}, fontFamily: 'Roboto' , fontSize: 32, fontWeight: 'bold', color: "primary", mb: 2}}> за вас и вашите потребители</Typography>                
            </Grid>
            <CartBox/>           
            <Grid item xl={6} md={4} xs={12} justifyContent="flex-start" alignItems='flex-end'>
                <Typography sx={{ textAlign:{xl: 'left', xs: 'center'}, fontWeight: '600', fontSize: 14, color: "primary.main", mt: 3}} variant="h6">Компании, които ни се довериха</Typography>
            </Grid>

            <Grid container item xl={6} md={8} xs={12} sx={{display: {xl: 'flex', xm: 'flex' , xs:'none'}, mt: 5, justifyContent:{xl: "flex-end", xs: 'center'} , alignItems:{xl: "flex-end", xs: 'center'}}} direction="row">
                <Box sx={{ borderRight: '1px solid #d4d4d4', py: 3 }}>
                    <SvgIcon viewBox='0 0 90 51' sx={{ mx: {xl: 2, xs: 0}, width: '80px', height: '51px' }}>
                        <Next />
                    </SvgIcon>
                </Box>
                <Box sx={{ borderRight: '1px solid #d4d4d4', py: 3 }}>
                    <SvgIcon viewBox='0 0 123 53' sx={{ mx: {xl: 2, xs: 0}, width: '80px', height: {xl:'53px', xs:'40px'} }}>
                        <Ferry />
                    </SvgIcon>
                </Box>
                <Box sx={{ borderRight: '1px solid #d4d4d4', py: 2}}>
                    <SvgIcon viewBox='0 0 58 69' sx={{ mx: {xl: 2, xs: 0}, width: '80px', height: {xl:'69px', xs: '65px'} }} >
                        <Dauto/>
                    </SvgIcon>
                </Box>
                <Box>
                    <SvgIcon viewBox='0 0 113 45' sx={{ width: '80px', height: '50px', mb: {xl: 4, xs: 0} , mt:{xl: 0, xs: 3} }}>
                        <Pimk/>
                    </SvgIcon>
                </Box>
            </Grid>

            {/* xs-version */}
            <Grid container item xl={6} md={8} xs={6} sx={{display:{ xl:'none', xm:'none', xs:'flex'}, justifyContent:'center' , alignItems:'center', direction: 'column'}}>
                <Box>
                    <SvgIcon viewBox='0 0 90 51' sx={{ width: 'auto', height: '50px' }}>
                        <Next />
                    </SvgIcon>
                </Box>
                <Box>
                    <SvgIcon viewBox='0 0 123 53' sx={{ pl: 3, width: 'auto', height: '50px' }}>
                        <Ferry />
                    </SvgIcon>
                </Box>                
            </Grid>

            <Grid container item xl={6} md={8} xs={6} sx={{display:{ xl:'none', xm:'none', xs:'inline'}, justifyContent:'center' , alignItems:'center', direction: 'column'}}>                
                <Box>
                    <SvgIcon viewBox='0 0 58 69' sx={{ pl: 5, width: 'auto', height: '50px' }} >
                        <Dauto/>
                    </SvgIcon>
                </Box>
                <Box>
                    <SvgIcon viewBox='0 0 113 45' sx={{ width: 'auto', height: '50px' }}>
                        <Pimk/>
                    </SvgIcon>
                </Box>
            </Grid>

        </Grid>
    );
}