import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import content1 from '../../content/servicesPage1';
import content2 from '../../content/servicesPage2';
import content3 from '../../content/servicesPage3';
import styles from '../../styles/styles';

const items1 = content1;
const items2 = content2;
const items3 = content3;

function CartServices1() {  
  return (
    items1.map(({ icon, title, description }, index) => (
      <Card key={index} variant="outlined" sx={styles.mainCard}>
        <CardContent sx={{ '&:last-child': { px: 0.5, py: 2 } }}>
          <Grid container alignItems="center">
            <Grid item xs={2} sx={{pl: 2}}>
              {icon}
            </Grid>
            <Grid item xs={10}>
              <Typography sx={{ fontSize: 14, fontWeight: 600 }} color="black" gutterBottom>
                {title}
              </Typography>
              <Typography variant="body2" sx={{ mb: 0.5 }}>
                {description}
              </Typography>              
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))
  );
}

function CartServices2() {  
  return (
    items2.map(({ icon, title, description }, index) => (
      <Card key={index} variant="outlined" sx={styles.mainCardRight}>
        <CardContent sx={{ '&:last-child': { px: 0.5 } }}>
          <Grid container alignItems="center">
            <Grid item xs={2} sx={{pl: 2}}>
              {icon}
            </Grid>
            <Grid item xs={10}>
              <Typography sx={{ fontSize: 14, fontWeight: 600 }} color="black" gutterBottom>
                {title}
              </Typography>
              <Typography variant="body2" sx={{ mb: 0.5 }}>
                {description}
              </Typography>              
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))
  );
}

function CartServices3() {  
  return (
    items3.map(({ icon, title, description }, index) => (
      <Card key={index} variant="outlined" sx={styles.mainCard}>
        <CardContent sx={{ '&:last-child': { px: 0.5, py: 2 } }}>
          <Grid container alignItems="center">
            <Grid item xs={2} sx={{pl: 2}}>
              {icon}
            </Grid>
            <Grid item xs={10}>
              <Typography sx={{ fontSize: 14, fontWeight: 600 }} color="black" gutterBottom>
                {title}
              </Typography>
              <Typography variant="body2" sx={{ mb: 0.5 }}>
                {description}
              </Typography>              
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))
  );
}

export {CartServices1 , CartServices2, CartServices3};