import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import CustomAvatar from '../components/CustomAvatar';
import WebhookIcon from '@mui/icons-material/Webhook';
import CloudIcon from '@mui/icons-material/Cloud';
import AppsIcon from '@mui/icons-material/Apps';
import RoomPreferencesIcon from '@mui/icons-material/RoomPreferences';

const items = [
    {
        icon:  <CustomAvatar><RoomPreferencesIcon color="primary" fontSize="small" /></CustomAvatar>,
        title: 'Изграждане на бизнес системи - CMS/ CRM',
        description:'Ефективно управление на бизнеса чрез персонализирани CMS и CRM системи.'        
    },
    {
        icon: <CustomAvatar><WebhookIcon color="primary" fontSize="small" /></CustomAvatar>,
        title: 'Създаване на Back-End платформи',
        description: 'Използваме PHP, C#, Node JS за създаване на платформи.'
        
    },
    {
        icon: <CustomAvatar><CloudIcon color="primary" fontSize="small" /></CustomAvatar>,
        title: 'Уеб хостинг',
        description: 'Предоставяме стабилен уеб хостинг за гарантирана сигурност.',        
    },
    {
      icon: <CustomAvatar><AppsIcon color="primary" fontSize="small" /></CustomAvatar>,
      title: 'Мобилни, десктоп и уеб приложения',
      description: 'Ориентирани към иновации, разработваме приложения за мобилни, десктоп и уеб платформи.',        
  },
];

export default items;

