import * as React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import styles from '../../styles/styles';


export default function Galery() {
  return (
    <Grid spacing={1} container sx={styles.template} paddingTop={9} paddingBottom={5}>

      <Grid item xl={6} md={6} xs={12}>
        <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: "primary.main", pb: 1 }} variant="h6">Галерия</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', py: 1, color: "primary.main" }} variant="h4">Вдъхновение
          <Typography display={'inline'} sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', color: "black" }}> в офиса</Typography>
        </Typography>
        <Typography sx={{ pb: 4 }}>Офисът е нашето пространство за развитие, комуникация и обмен на идеи, където времето минава неусетно.
        </Typography>
      </Grid>

      <Grid spacing={3} container item xl={12} md={12} xs={12} paddingBottom={1} sx={{ display: { xl: 'flex', xm:'flex', xs: 'none', md: 'flex' } }}>
        <Grid item xl={9} md={6} xm={6} xs={12} >
          <Box component="img" sx={{ pl: { xl: 0, xs: 0 }, width: '100%', borderRadius: 5 }} alt="The img." src="/SKN_9600.jpg" />
        </Grid>
        <Grid item xl={3} md={6} xm={6} xs={12}>
          <Box component="img" sx={{ pl: { xl: 0, xs: 0 }, width: '100%', borderRadius: 5 }} alt="The img." src="/IMG_6974.jpg" />
        </Grid>
      </Grid>

      <Grid spacing={3} container item xl={12} md={12} xs={12} paddingBottom={2} sx={{ display: { xl: 'flex', xm:'flex', xs: 'none', md: 'flex' } }}>
        <Grid item xl={3} md={3} xm={6} xs={12}>
          <Box component="img" sx={{ pl: { xl: 0, xs: 0 }, width: '100%', borderRadius: 5 }} alt="The img." src="/SKN_9922.jpg" />
        </Grid>
        <Grid item xl={7} md={7} xm={6} xs={12}>
          <Box component="img" sx={{ pl: { xl: 0, xs: 0 }, width: '100%', borderRadius: 5 }} alt="The img." src="/SKN_0007.jpg" />
        </Grid>
        <Grid item xl={2} md={2} xm={6} xs={12}>
          <Box component="img" sx={{ pl: { xl: 0, xs: 0 }, width: '100%', borderRadius: 5 }} alt="The img." src="/plovdiv.jpg" />
        </Grid>
      </Grid>

      <Box sx={{ mt: 1, my: 2, mx: 2, display: { xs: 'flex', xm: 'none', md: 'none' } }} overflow='hidden'>
        <Swiper pagination={true} modules={[Pagination]} slidesPerView={'auto'} spaceBetween={30}>

          <SwiperSlide>
            <Box component="img" sx={{ pl: { xl: 0, xs: 0 }, width: '100%', height: '484px', borderRadius: 5 }} alt="The img." src="/SKN_9600_slide.jpg" />
          </SwiperSlide>

          <SwiperSlide>
            <Box component="img" sx={{ pl: { xl: 0, xs: 0 }, width: '100%', height: '484px', borderRadius: 5 }} alt="The img." src="/IMG_6974.jpg" />
          </SwiperSlide>

          <SwiperSlide>
            <Box component="img" sx={{ pl: { xl: 0, xs: 0 }, width: '100%', height: '484px', borderRadius: 5 }} alt="The img." src="/SKN_9922.jpg" />
          </SwiperSlide>

          <SwiperSlide>
            <Box component="img" sx={{ pl: { xl: 0, xs: 0 }, width: '100%', height: '484px', borderRadius: 5 }} alt="The img." src="/SKN_0007_slide.jpg" />
          </SwiperSlide>

          <SwiperSlide>
            <Box component="img" sx={{ pl: { xl: 0, xs: 0 }, width: '100%', height: '484px', borderRadius: 5 }} alt="The img." src="/plovdiv_slide.jpg" />
          </SwiperSlide>
        </Swiper>
      </Box>

    </Grid>
  );
}    