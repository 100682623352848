import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import content from '../content/boxcontent';
import styles from '../styles/styles';

const items = content;

export default function CartBox() {
    return (
        items.map(({ icon, title, description }, index) => (
            <Grid key={index} item xl={3} xs={12} md={6} sm={6} xm={6}>
                <Card key={index} variant="outlined" sx={styles.boxCard}>
                    <CardContent sx={{ '&:last-child': { py: 3, px: 3 } }}>
                        {icon}
                        <Grid container alignItems="center">
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: 14, fontWeight: '600', mt: 3, mb: 1 }} color="black">
                                    {title}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        ))
    );
}