import * as React from 'react';
import { useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";
import styles from '../../styles/styles';


export default function Form() {

  const refCaptcha = useRef()
  const form = useRef();
  const [open, setOpen] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    const token = refCaptcha.current.getValue()
    const formData = {
      job_user: form.current.elements.job_user.value,
      job_email: form.current.elements.job_email.value,
      job_phone: form.current.elements.job_phone.value,
      job_cv: form.current.elements.job_cv.value,
      job_description: form.current.elements.job_description.value,
      job_message: form.current.elements.job_message.value,
      "g-recaptcha-response": token,
    };

    emailjs.send(`${process.env.REACT_APP_MAIL_SERVICE}`, `${process.env.REACT_APP_MAIL_JOB_TEMPLATE}`, formData, `${process.env.REACT_APP_MAIL_PUBLIC_KEY}`)
      .then((result) => {
        refCaptcha.current.reset();
        form.current.reset();
        setOpen(true);
        console.log(result.text);
        console.log("message sent!")
      }, (error) => {
        console.log(error.text);
        console.log("error sending message, try again!")
      });
     //e.target.reset();      
  }

  const handleClose = (event) => {
    setOpen(false);
  };

  return (
    <Grid spacing={1} container sx={styles.template} paddingY={3} borderBottom='1px solid #d4d4d4'>

      <Grid item xl={12} md={6} xs={12} overflow='hidden'>
        <Typography sx={{ fontSize: 24, fontWeight: 'bold', color: "primary", pb: 1 }} variant="h6">Кандидаствай за този позиция</Typography>

        <Box component="form" onSubmit={sendEmail} ref={form} sx={{ width: '100%', maxWidth: '100%', mt: 2, my: { xl: 2, xs: 0 } }} >
          <TextField required id="job_user" name='job_user' label="Име фамилия" variant="outlined" InputProps={{ style: { borderRadius: '10px' } }} sx={{ mr: 3, width: { xl: '32%', xs: '100%' } }} margin="normal" />
          <TextField required id="job_email" name='job_email' label="Имейл" variant="outlined" InputProps={{ style: { borderRadius: '10px' } }} sx={{ mr: 3, width: { xl: '31%', xs: '100%' } }} margin="normal" />
          <TextField required id="job_phone" name='job_phone' label="Телефон" variant="outlined" InputProps={{ style: { borderRadius: '10px' } }} sx={{ width: { xl: '32%', xs: '100%' } }} margin="normal" />
          <TextField hidden id="job_description" name='job_description' value={window.location.pathname.toString().replace(/^\/|\/$/g, '')} sx={{ display: 'none' }} />
          <TextField required id="job_cv" name='job_cv' label="Link to CV" fullWidth variant="outlined" InputProps={{ style: { borderRadius: '10px' } }} margin="normal" />
          <TextField required id="job_message" name='job_message' label="Съобщение" fullWidth multiline rows={6} variant="outlined" InputProps={{ style: { borderRadius: '10px' } }} margin="normal" />
          <ReCAPTCHA style={{ marginTop: { xl: 0, xs: 16 } }} ref={refCaptcha} sitekey='6LfMIaYpAAAAACrHKXOBM3xRnq_BajHmt1lKzyFD' />

          <Typography sx={{ fontFamily: 'Roboto', fontSize: 14, py: 1 }}>Давам съгласие за обработката на моите лични данни съгласно Политиката за
            обработка на лични данни на кандидати за работа за целите на разглеждане и оценка на моето заявление, както и провеждането на процедури за подбор и набиране на персонал..
          </Typography>


          <Typography sx={{ fontFamily: 'Roboto', fontSize: 14 }}>*За да кандидатствате за тази позиция, моля, изпратете ни портфолиото си като линк към вашето CV.
          </Typography>

          <Button type="submit" onClick={() => form.current.reportValidity()} variant="contained" color="primary" sx={{ width: { xl: '20%', md: '40%', xm: '40%', xs: '100%' }, my: { xl: 1, md: 3, xm: 3, xs: 3 }, size: 'large', height: 40, borderRadius: 8, fontSize: 13, px: 8 }}>Изпрати</Button>

        </Box>

        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>Email was sent successfully!</Alert>
        </Snackbar>

      </Grid>

    </Grid>
  );
}    