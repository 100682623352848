import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import CustomAvatar from '../components/CustomAvatar';
import CampaignIcon from '@mui/icons-material/Campaign'; 
import ForumIcon from '@mui/icons-material/Forum'; 
import CreateIcon from '@mui/icons-material/Create';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'; 

const items = [
    {
        icon:  <CustomAvatar><CampaignIcon color="primary" fontSize="small" /></CustomAvatar>,
        title: 'Изготвяне на кампании, постове',
        description:'Разработка на ефективни кампании и съдържание за социални медии.'        
    },
    {
        icon: <CustomAvatar><ForumIcon color="primary" fontSize="small" /></CustomAvatar>,
        title: 'Поддръжка на социални мрежи',
        description: 'Професионална поддръжка и управление на социални мрежи.'
        
    },
    {
        icon: <CustomAvatar><CreateIcon color="primary" fontSize="small" /></CustomAvatar>,
        title: 'Копирайтинг',
        description: 'Качествен копирайтинг, създаващ запомнящо се бранд съдържание.',        
    },
    {
      icon: <CustomAvatar><VideoLibraryIcon color="primary" fontSize="small" /></CustomAvatar>,
      title: 'Видео маркетинг',
      description: 'Видео маркетинг решения за динамично и въздействащо присъствие.',        
  },
];

export default items;

