import * as React from 'react';
import AppNavBar from '../../components/AppNavBar';
import Container from '@mui/material/Container';
import PreFooter from '../../components/PreFooter';
import BottomNavBar from '../../components/BottomNavBar';
import Form from './Form';
import DescriptionOneFront from './DescriptionOneFront';
import DescriptionTwoFront from './DescriptionTwoFront';

export default function JobsUiPage() {
  return (
    <Container disableGutters maxWidth="auto">
      <AppNavBar />
      <DescriptionOneFront/>
      <DescriptionTwoFront/>
      <Form/>
      <PreFooter />
      <BottomNavBar />
    </Container>
  );
}
