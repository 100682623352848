import * as React from 'react';
import { CartLine1, CartLine2, CartLine3, CartLine4 } from './CartSmall';
import { CartLineMobile1, CartLineMobile2, CartLineMobile3, CartLineMobile4, CartLineMobile5, CartLineMobile6, CartLineMobile7, CartLineMobile8 } from './CartSmallMobile';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from '../styles/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";


export default function CartMain() {
    return (
        <Grid container sx={styles.container1}>
            <Grid item xs={12}>
                <Typography sx={{ color: "primary.main", fontWeight: 'bold', fontSize: 16 }} >Технологични</Typography>
            </Grid>

            <Grid container spacing={3} sx={{ display: { xl: 'flex', md: 'flex', xm:'flex', xs: 'none' } }}>
                <CartLine1 />
                <CartLine2 />
            </Grid>

            <Box sx={{ display: { xs: 'flex', md: 'none', xm:'none' }, py: 2, overflow: 'hidden' }}>
                <Swiper slidesPerView={1} spaceBetween={25} width={273}>
                    <Grid container spacing={3} >
                        <Grid item xl={3} xs={6}>
                            <SwiperSlide key={1}>
                                <CartLineMobile1 />
                            </SwiperSlide>
                        </Grid>
                        <Grid item xl={3} xs={6}>
                            <SwiperSlide key={2}>
                                <CartLineMobile2 />
                            </SwiperSlide>
                        </Grid>
                        <Grid item xl={3} xs={6}>
                            <SwiperSlide key={3}>
                                <CartLineMobile3 />
                            </SwiperSlide>
                        </Grid>
                        <Grid item xl={3} xs={6}>
                            <SwiperSlide key={4}>
                                <CartLineMobile4 />
                            </SwiperSlide>
                        </Grid>
                    </Grid>
                </Swiper>
            </Box>

            <Grid item xs={12}>
                <Typography sx={{ color: "orange", fontWeight: 'bold', mt: 7, mb: 2, fontSize: 16 }}>Креативни</Typography>
            </Grid>

            <Grid container spacing={3} sx={{ display: { xl: 'flex', md: 'flex', xm: 'flex', xs: 'none' } }}>
                <CartLine3 />
                <CartLine4 />
            </Grid>

            <Box sx={{ display: { xs: 'flex', md: 'none', xm:'none' }, py: 2, overflow: 'hidden' }}>
                <Swiper slidesPerView={1} spaceBetween={25} width={273}>
                    <Grid container spacing={3} >
                        <Grid item xl={3} xs={6}>
                            <SwiperSlide key={5}>
                                <CartLineMobile5 />
                            </SwiperSlide>
                        </Grid>
                        <Grid item xl={3} xs={6}>
                            <SwiperSlide key={6}>
                                <CartLineMobile6 />
                            </SwiperSlide>
                        </Grid>
                        <Grid item xl={3} xs={6}>
                            <SwiperSlide key={7}>
                                <CartLineMobile7 />
                            </SwiperSlide>
                        </Grid>
                        <Grid item xl={3} xs={6}>
                            <SwiperSlide key={8}>
                                <CartLineMobile8 />
                            </SwiperSlide>
                        </Grid>
                    </Grid>
                </Swiper>
            </Box>

        </Grid>
    );
}