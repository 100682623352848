import * as React from 'react';
import { useRef, useState } from 'react';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Button from '@mui/material/Button';
import styles from '../../styles/styles';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";


export default function Main() {

  const refCaptcha = useRef()
  const form = useRef();
  const [open, setOpen] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    const token = refCaptcha.current.getValue()
    const formData = {
      from_name: form.current.elements.from_name.value,
      from_email: form.current.elements.from_email.value,
      phone: form.current.elements.phone.value,
      service_name: form.current.elements.service_name.value,
      message: form.current.elements.message.value,
      "g-recaptcha-response": token,
    };

    emailjs.send(`${process.env.REACT_APP_MAIL_SERVICE}`, `${process.env.REACT_APP_MAIL_CONTACT_TEMPLATE}`, formData, `${process.env.REACT_APP_MAIL_PUBLIC_KEY}`)
      .then((result) => {
        refCaptcha.current.reset();
        form.current.reset();
        setOpen(true);
        console.log(result.text);
        console.log("message sent!")
      }, (error) => {
        console.log(error.text);
        console.log("error sending message, try again!")
      });
  }

  const handleClose = (event) => {
    setOpen(false);
  };

  return (

    <Grid container sx={styles.template} paddingY={4}>

      <Grid item xl={6} md={6} xs={12} overflow='hidden'>
        <Box>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: "primary.main"}} variant="h6">Контакти</Typography>
          <Typography sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', letterSpacing: 1, py: 1 }} variant="h4">Свържете се с
            <Typography display={'inline'} sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', color: "primary.main"}}> нас</Typography>
          </Typography>
          <Typography>Сътрудничим с вас, за да създаваме отвъд възможностите.</Typography>
        </Box>

        <Box component="form" onSubmit={sendEmail} ref={form} sx={{ width: '100%', maxWidth: '100%', my: { xs: 0 } }}>
          <TextField required id="from_name" name='from_name' label="Име и фамилия" fullWidth variant="outlined" InputProps={{ style: { borderRadius: '10px' } }} margin="normal" />
          <TextField required id="from_email" name='from_email' type="email" label="Имейл" variant="outlined" InputProps={{ style: { borderRadius: '10px' } }} sx={{ mr: 3, width: { xl: '48%', xs: '100%' } }} margin="normal" />
          <TextField required id="phone" name='phone' label="Телефон" variant="outlined" InputProps={{ style: { borderRadius: '10px' } }} sx={{ width: { xl: '47%', xs: '100%' } }} margin="normal" />
          <TextField required id="service_name" name='service_name' label="От каква услуга се нуждаете" fullWidth variant="outlined" InputProps={{ style: { borderRadius: '10px' } }} margin="normal" />
          <TextField required id="message" name='message' label="Разкажете ни малко повече за вашия проект" fullWidth multiline rows={3} variant="outlined" InputProps={{ style: { borderRadius: '10px' } }} margin="normal" />
          <ReCAPTCHA style={{ marginTop: { xl: 0, xs: 16 } }} ref={refCaptcha} sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} />
          <Button type="submit" onClick={() => form.current.reportValidity()} variant="contained" color="primary" sx={{ width: { xs: '100%', md: '40%' }, my: { xl: 1, md: 3, xm: 3, xs: 3 }, size: 'large', height: 40, borderRadius: 8, fontSize: 13, px: 8 }}>Изпрати</Button>
        </Box>

        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>Email was sent successfully!</Alert>
        </Snackbar>

      </Grid>

      <Grid container item xl={6} md={6} xs={12} overflow='hidden' direction="row" gap={2}>

        <Grid item xl={12} display='flex' sx={{ mx: 6}}>
          <Box sx={{ mt: 2, mr: 2 }}>
            <PlaceIcon color="primary" fontSize="small" />
          </Box>
          <Box>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: "black" }}>Адрес</Typography>
            <Typography sx={{ fontSize: 16, color: "black" }}>ул. Рогошко шосе 36,  гр. Пловдив</Typography>
          </Box>
        </Grid>

        <Grid item xl={12} display='flex' sx={{ mx: 6}}>
          <Box sx={{ mt: 2, mr: 2 }}>
            <PhoneIcon color="primary" fontSize="small" />
          </Box>
          <Box>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: "black" }}>Телефон</Typography>
            <Typography sx={{ fontSize: 16, color: "black" }}>+359 893 406767</Typography>
          </Box>
        </Grid>

        <Grid item xl={12} display='flex' sx={{ mx: 6}}>
          <Box sx={{ mt: 2, mr: 2 }}>
            <EmailIcon color="primary" fontSize="small" />
          </Box>
          <Box>
            <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: "black" }}>Имейл</Typography>
            <Typography sx={{ fontSize: 16, color: "black" }}>support@nxtsp.com</Typography>
          </Box>
        </Grid>

        <Box flexGrow={1} sx={{ width: '100%', height: { xl: '360px', xs: '400px' }, ml: { xl: 6, md: 6, xs: 0 }, display: { xl: 'flex', md: 'flex', xs: 'flex' } }}>
          <APIProvider apiKey={'AIzaSyDRQjjoy-nQ1VZVPGAwowWulOGDvDTWSsw'}>
            <Map defaultZoom={15} defaultCenter={{ lat: 42.16287, lng: 24.77155 }} gestureHandling={'greedy'} disableDefaultUI={true} />
          </APIProvider>
        </Box>
      </Grid>
    </Grid>
  );
}    