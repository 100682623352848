import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import styles from '../../styles/styles';


export default function DescriptionOneBack() {
  return (
    <Grid spacing={1} container sx={styles.template} paddingTop={14}>

      <Grid item xl={8} md={6} xs={12} overflow='hidden'>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold' }} variant="h4">Back-End Developer</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 18, py: 2 }}>Next To Space търси не просто Back- End Developer, 
        а истински иноватор, който вижда възможности там, където други виждат предизвикателства. Ако сте създател по душа, 
        който обича да изгражда скалируеми и ефективни системи, които служат като основа на иновативни продукти, вашето място е с нас. 
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 18, pb: 1 }}>В Next To Space, всяка логика е написана по реален бизнес модел
         и всяка инициатива е шанс да се изгради нещо значимо. Нека заедно създаваме продукти, които оставят нашия отпечатък в историята на технологиите.
        </Typography>

        <Box sx={{ pt: 6 }}>
          <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">Детайли за позицията</Typography>
          <List sx={{ listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}><b>Локация:</b> гр. Пловдив, ул. Рогошко шосе №36</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}><b>Работно време: </b>Осемчасов работен ден - от понеделник до петък; 9:00 до 18:00</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}><b>Вид на работата: </b>Постоянна работа</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}><b>Години опит: </b>от 3 до 10+</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}><b>Отпуск: </b>20 дни</Typography></ListItem>
          </List>
        </Box>

        <Box sx={{ pt: 6 }}>
          <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">Отговорности</Typography>
          <List sx={{ listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Разработка и поддръжка на софтуерни приложения, които да автоматизират и оптимизират операциите на компанията.</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Интеграция на различни компютърни системи за управление.</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Анализ на нуждите на клиентите и разработка на персонализирани софтуерни решения, които да отговарят на техните изисквания</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Оптимизация на бази данни и създаване на ефективни алгоритми за обработка на големи обеми от данни.</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Сътрудничество с други членове на екипа за изграждане на интегрирани софтуерни решения</Typography></ListItem>            
          </List>
        </Box>

      </Grid>

    </Grid>
  );
}    