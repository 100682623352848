import * as React from 'react';
import AppNavBar from '../../components/AppNavBar';
import Container from '@mui/material/Container';
import PreFooter from '../../components/PreFooter';
import BottomNavBar from '../../components/BottomNavBar';
import Description from './Description';
import Contacts from '../../components/Contacts';

export default function TermsPage() {
  return (
    <Container disableGutters maxWidth="auto">
      <AppNavBar />
      <Description/>
      <Contacts />      
      <PreFooter />
      <BottomNavBar />
    </Container>
  );
}
