import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Rocket } from "../../svg/rocket-illustration-466x255.svg";
import styles from '../../styles/styles';


export default function Main() {
  return (
    <Grid spacing={1} container sx={styles.template} paddingTop={9} paddingBottom={5} overflow='hidden'>

      <Grid item xl={6} md={6} xs={12}>
        <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: "primary.main", pb: 1 }} variant="h6">Мисия и визия</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', py: 1 }} variant="h4">Превърнете идеите си в
          <Typography display={'inline'} sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', color: "primary.main" }}> успех</Typography>
        </Typography>        
        <Typography>Сътрудничим си с вас, за да създаваме отвъд възможностите. Ние сме дизайнери, разработчици, маркетолози и мениджъри
                    с една и съща визия - да изграждаме по-добър свят за хората чрез ефективни продукти.
        </Typography>
        <Typography sx={{ py: 1 }}>Специализираме във всички аспекти на дигиталния дизайн и разработка, включително уеб дизайн, разработка на софтуер и онлайн маркетинг. 
                    Ние помагаме на компаниите да се развиват чрез бързи и функционални решения.
        </Typography>
      </Grid>

      <Grid container item xl={6} md={6} xs={12} alignItems='center' justifyContent='center'>
        <SvgIcon viewBox='0 0 466 255' sx={{ width: {xl: '466px', xs: 405}, height: 'auto' }}>
          <Rocket />
        </SvgIcon>
      </Grid>

    </Grid>
  );
}    