const styles = {
  template:{
    px: {  
      xxl: 48, 
      xl: 24, 
      lg: 24, 
      md: 3, 
      sm: 2,       
      xs: 2 
    }
  },
  templateBorder:{
    px: {  
      xxl: 48, 
      xl: 24, 
      lg: 24, 
      md: 3, 
      sm: 2,      
      xs: 2
    },
    borderBottom: '1px solid #d4d4d4'
  },
  container1: {   
    px: {
      xxl:48,            
      xl: 24,
      lg: 24,
      md: 3,
      sm: 2,
      xs: 2
    },   
    my: 5
  },
  container2: {    
    px: {
      xxl:48,            
      xl: 24,
      lg: 24,
      md: 3,
      sm: 2,
      xs: 2
    },
    py: {
      xl: 8,
      xs : 5
    },
    backgroundColor: '#fafcff'
  },
  serviceContainer1:{
    px: {  
      xxl: 48, 
      xl: 24, 
      lg: 24, 
      md: 3, 
      sm: 2, 
      xs: 2 }, 
      py: 9, 
      borderBottom: '1px solid #d4d4d4'
  },
  serviceContainer2:{
    px: { 
      xxl:48, 
      xl: 24, 
      lg: 24, 
      md: 3,
      sm: 2, 
      xs: 2}, 
      py: {
        xl: 4, 
        xs: 3
      }, 
      backgroundColor: '#fafcff', 
      borderBottom: '1px solid #d4d4d4'
  },
  serviceContainer3:{
    px: {  
      xxl: 48, 
      xl: 24, 
      lg: 12, 
      md: 3, 
      sm: 2, 
      xs: 2 }, 
      py: 9, 
      borderBottom: '1px solid #d4d4d4'
  },
  serviceInfoText:{
    textAlign: 'center', 
    mx: 1, 
    fontSize: 32, 
    fontWeight: 'bold'
  },
  menu: {
    fontSize: "small",
    color: "#555E6B",
    fontStyle: 'normal',
    fontWeight: 600,
  },
  mainCard: {
    mr: {xl: 1, md: 2, xs: 0}, my: 1, 
    borderRadius: 5, ':hover': 
    {
      boxShadow: '0px -1px 6px 0px rgba(46, 151, 255, 0.18), 0px 1px 6px 0px rgba(46, 151, 255, 0.18), 1px 0px 6px 0px rgba(46, 151, 255, 0.18), -1px 0px 6px 0px rgba(46, 151, 255, 0.18)', 
    borderColor: "primary.main",
    backgroundColor: '#fafcff'}
  },
  mainCardRight: {
    ml: {xl: 1, md: 0, xs: 0}, my: 1, 
    borderRadius: 5, ':hover': 
    {
      boxShadow: '0px -1px 6px 0px rgba(46, 151, 255, 0.18), 0px 1px 6px 0px rgba(46, 151, 255, 0.18), 1px 0px 6px 0px rgba(46, 151, 255, 0.18), -1px 0px 6px 0px rgba(46, 151, 255, 0.18)', 
    borderColor: "primary.main",
    backgroundColor: '#fafcff'}
  },
  boxCard: {
    borderColor: "primary.main", 
    backgroundColor: '#fafcff',     
    borderRadius: 5, ':hover': 
    {
      boxShadow: '0px -1px 6px 0px rgba(46, 151, 255, 0.18), 0px 1px 6px 0px rgba(46, 151, 255, 0.18), 1px 0px 6px 0px rgba(46, 151, 255, 0.18), -1px 0px 6px 0px rgba(46, 151, 255, 0.18)', 
    borderColor: "primary.main",
    backgroundColor: '#fafcff'}
  },
  footerMain: {
    py: 0.5,  
    fontSize: 14, 
    fontWeight: 'bold', 
    color: "primary.main",
  },
  footerText: {
    py: 0.5,  
    fontSize: 14, 
    fontWeight: '300', 
    color: "primary",
  },
  menuFooter: {
    fontSize: { xl:14, xm: 12, xs: 12},
    color: "black",
    fontStyle: 'normal',
    fontWeight: 300,
  },
  body1: {
    variant: "body1",
    color: "text.secondary"
  },
  checkBlue: {
    my: -0.5,
    mx: 0.5, 
    color:'primary.main'
  },
  mainBold: {
    fontSize: 14,
    fontWeight: 'bold',
    color: "primary.main",
  },
};

export default styles;

