import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import styles from '../../styles/styles';


export default function Information() {
  return (
    <Grid spacing={3} container sx={{ px: { xxl: 48, xl: 24, lg: 24, md: 3, sm: 3, xs: 2 }, backgroundColor: 'black', pb: 3}}>

      <Box sx={{ display: { xl: 'none', md: 'none', xm:'none', xs: 'flex' } }}>
        <Grid container item xl={2} xm={12} xs={7} sx={{ mt: 8, mx: {xl: 4, sm: 8, xs: 4} }}>
          <Box sx={{ borderLeft: 1, mb: 2 }}>
            <Typography color='#99CCF3' sx={{ mx: 1, fontSize: 24, fontWeight: 'bold', textAlign: 'center' }}>120</Typography>
            <Typography color='#9198A3' sx={{ mx: 1, fontSize: 14, mb: 2 }}>Реализирани мисии</Typography>
          </Box>
          <Box sx={{ borderLeft: 1}}>
            <Typography color='#99CCF3' sx={{ mx: 1, fontSize: 24, fontWeight: 'bold', textAlign: 'center'  }}>14</Typography>
            <Typography color='#9198A3' sx={{ mx: 1, fontSize: 14 }}>Креативни проекти</Typography>
          </Box>
        </Grid>

        <Grid container item xl={2} xs={6} sx={{ mt: 8 }}>
          <Box sx={{ borderLeft: 1, mb: 2}}>
            <Typography color='#99CCF3' sx={{ mx: 1, fontSize: 24, fontWeight: 'bold', textAlign: 'center', pr: 3}}>3</Typography>
            <Typography color='#9198A3' sx={{ mx: 1, fontSize: 14, mb: 2 }}>Различни отдела</Typography>
          </Box>
          <Box sx={{ borderLeft: 1}}>
            <Typography color='#99CCF3' sx={{ mx: 1, fontSize: 24, fontWeight: 'bold', textAlign: 'center'  }}>5</Typography>
            <Typography color='#9198A3' sx={{ mx: 1, fontSize: 14 }}>Години опит</Typography>
          </Box>
        </Grid>
      </Box>

      <Grid container item xxl={12} xl={12} md={12} xm={12} xs={7} direction='row' justifyContent='center' alignItems='center' sx={{ display: { xl: 'flex', md: 'flex', xm: 'flex', xs: 'none' } }} gap={{xl: 7, xm: 1}}>
        <Box>
          <Typography color='#99CCF3' fontFamily='Roboto' sx={styles.serviceInfoText}>120</Typography>
          <Typography color='#9198A3' sx={{ mx: 1, fontSize: 14 }}>Реализирани мисии</Typography>
        </Box>
        <Box>
          <Typography color='#99CCF3' fontFamily='Roboto' sx={styles.serviceInfoText}>3</Typography>
          <Typography color='#9198A3' sx={{ mx: 1, fontSize: 14 }}>Различни отдела</Typography>
        </Box>
        <Box>
          <Typography color='#99CCF3' fontFamily='Roboto' sx={styles.serviceInfoText}>14</Typography>
          <Typography color='#9198A3' sx={{ mx: 1, fontSize: 14 }}>Креативни проекти</Typography>
        </Box>
        <Box>
          <Typography color='#99CCF3' fontFamily='Roboto' sx={styles.serviceInfoText}>5</Typography>
          <Typography color='#9198A3' sx={{ mx: 1, fontSize: 14 }}>Години опит</Typography>
        </Box>
      </Grid>

      <Grid item xxl={6} xl={6} md={6} xs={12}>

        <Box sx={{ backgroundColor: '#171C22', borderRadius: 2, px: 2, mb: 3 }}>
          <Typography color='white' sx={{ textAlign: 'left', pt: 2, fontSize: 15}}>
            "NXTSP е безценен инструмент за наблюдение на финансите и представянето на нашата компания.
            Предоставя ни достъп в реално време до актуални данни за работата на нашите колеги, паричните
            потоци и финансовите резултати. С NXTSP вече не трябва да разчитаме на счетоводители,
            за да имаме доверие във финансовото ни състояние; необходими са само няколко щраквания,
            за да получим информацията, от която се нуждаем.".
          </Typography>
          <Box alignItems="center" sx={{ justifyContent: { xl: 'flex-start', xs: 'center' }, display: 'flex', py: 2 }}>
            <Avatar sx={{ width: 60, height: 60, border: 2, borderColor: 'primary.main' }} src="/nasie-hashimova.jpg"></Avatar>
            <Typography color='white' sx={{ mx: 2, fontWeight: 'bold' }} variant="h7">Насие Хашимова<br />
                  <Typography display='inline' color='white' sx={{ fontWeight: '300' }}>Ръководител Морска спедиция</Typography>
            </Typography>
          </Box>
        </Box>

        <Box sx={{ backgroundColor: '#171C22', borderRadius: 2, px: 2 }}>
          <Typography color='white' sx={{ textAlign: 'left', pt: 2}}>
            "Благодарение на NXTSP успяхме значително да намалим времето, изразходвано за докладване и оценка на
            резултатите от проектите. Това, което преди ни отнемаше един час, с NXTSP го имаме за 10 минути."
          </Typography>
          <Box alignItems="center" sx={{ justifyContent: { xl: 'flex-start', xs: 'center' }, display: 'flex', py: 2 }}>
            <Avatar sx={{ width: 60, height: 60, border: 2, borderColor: 'primary.main' }} src="/rumen-minkov.jpg"></Avatar>
            <Typography color='white' sx={{ mx: 2, fontWeight: 'bold' }} variant="h7">Румен Минков<br />
                  <Typography display='inline' color='white' sx={{ fontWeight: '300' }}>Ръководител Логистика</Typography>
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xxl={6} xl={6} md={6} xs={12}>

        <Box sx={{ backgroundColor: '#171C22', borderRadius: 2, px: 2, mb: 3 }}>
          <Typography color='white' sx={{ textAlign: 'left', pt: 2 }}>
            "Страхотно е, че можем да имаме всичко - от оценка на разходите, планиране на капацитета, поставяне на
            задачи до оценка на проекта - всичко на едно място. Това спестява много време."
          </Typography>
          <Box alignItems="center" sx={{ justifyContent: { xl: 'flex-start', xs: 'center' }, display: 'flex', py: 2 }}>
            <Avatar sx={{ width: 60, height: 60, border: 2, borderColor: 'primary.main' }} src="/antonii-tonev.jpg">H</Avatar>
            <Typography color='white' sx={{ mx: 2, fontWeight: 'bold' }} variant="h7">Антоний Тонев<br />
                  <Typography display='inline' color='white' sx={{ fontWeight: '300' }}>Управител на Moto House</Typography>
            </Typography>
          </Box>
        </Box>

        <Box sx={{ backgroundColor: '#171C22', borderRadius: 2, px: 2 }}>
          <Typography color='white' sx={{ textAlign: 'left', pt: 2 }}>
            "В NXTSP ме устройва, че мога да създавам оценки на разходите и също така мога да ги одобрявам.
            Ръководя екип от мениджъри на акаунти, така че планирането на капацитета, прегледът на проекта,
            задачите или отчетът на едно място е голяма полза."
          </Typography>
          <Box alignItems="center" sx={{ justifyContent: { xl: 'flex-start', xs: 'center' }, display: 'flex', py: 2 }}>
            <Avatar sx={{ width: 60, height: 60, border: 2, borderColor: 'primary.main' }} src="/atanas-dimbarev.jpg">H</Avatar>
            <Typography color='white' sx={{ mx: 2, fontWeight: 'bold' }} variant="h7">Атанас Димбарев<br />
                  <Typography display='inline' color='white' sx={{ fontWeight: '300' }}>Управител на Dr.Auto</Typography>
            </Typography>
          </Box>
        </Box>

      </Grid>
    </Grid>
  );
}