import * as React from 'react';
import AppNavBar from '../../components/AppNavBar';
import Main from './Main';
import Team from './Team';
import Contacts from '../../components/Contacts';
import Container from '@mui/material/Container';
import PreFooter from '../../components/PreFooter';
import BottomNavBar from '../../components/BottomNavBar';
import CartBox from './CartBox';
import Values from './Values';
import Galery from './Galery';

export default function AboutPage() {
  return (
    <Container disableGutters maxWidth="auto">
      <AppNavBar />
      <Main/>
      <CartBox/>
      <Team/>
      <Values/>
      <Galery/>         
      <Contacts />
      <PreFooter />
      <BottomNavBar />
    </Container>
  );
}
