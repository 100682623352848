import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CardContent from '@mui/material/CardContent';
import CustomAvatar from '../components/CustomAvatar';
import content1 from '../content/cardLine1';
import content2 from '../content/cardLine2';
import content3 from '../content/cardLine3';
import content4 from '../content/cardLine4';

const items1 = content1;
const items2 = content2;
const items3 = content3;
const items4 = content4;

function CartLine1() {
    return (
        items1.map(({ icon, title, description }, index) => (
            <Grid key={index} item xl={3} md={4} xm={6} xs={6}>
                <Card key={index} variant="outlined" sx={{ mt: 1, borderRadius: 4 }}>
                    <CardContent sx={{ '&:last-child': { py: 3, px: 1 } }}>
                        <Grid container alignItems="left">
                            <Grid item xs={3}>
                                {icon}
                            </Grid>
                            <Grid item xs={9}>
                                <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary">
                                    {title}
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: 11 }}>
                                    {description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        ))
    );
}

function CartLine2() {
    return (
        items2.map(({ icon, title, description }, index) => (
            <Grid key={index} item xl={3}  md={4} xm={6} xs={6}>
                <Card key={index} variant="outlined" sx={{ borderRadius: 4 }}>
                    <CardContent sx={{ '&:last-child': { py: 3, px: 1 } }}>
                        <Grid container alignItems="left">
                            <Grid item xs={3}>
                                {icon}
                            </Grid>
                            <Grid item xs={9}>
                                <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary">
                                    {title}
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: 11 }}>
                                    {description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        ))
    );
}

function CartLine3() {
    return (
        items3.map(({ icon, title, description }, index) => (
            <Grid key={index} item xl={3}  md={4} xm={6} xs={6}>
                <Card key={index} variant="outlined" sx={{ borderRadius: 4 }}>
                    <CardContent sx={{ '&:last-child': { py: 3, px: 1 } }}>
                        <Grid container alignItems="left">
                            <Grid item xs={3}>
                                {icon}
                            </Grid>
                            <Grid item xs={9}>
                                <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary">
                                    {title}
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: 11 }}>
                                    {description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        ))
    );
}

function CartLine4() {
    return (
        items4.map(({ icon, title, description }, index) => (
            <Grid key={index} item xl={3}  md={4} xm={6} xs={6}>
                <Card key={index} variant="outlined" sx={{borderRadius: 4 }}>
                    <CardContent sx={{ '&:last-child': { py: 3, px: 1 } }}>
                        <Grid container alignItems="left">
                            <Grid item xs={3}>
                                {icon}
                            </Grid>
                            <Grid item xs={9}>
                                <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary">
                                    {title}
                                </Typography>
                                <Typography variant="body2" sx={{ fontSize: 11 }}>
                                    {description}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        ))
    );
}

export {CartLine1 , CartLine2, CartLine3, CartLine4};