import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import LinkIcon from '@mui/icons-material/Link';
import MenuLink from './MenuLink';
import CustomAvatar from './CustomAvatar';

export default function DrawerMenu() {

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <Box sx={{ display: { sm: '', md: 'none' } }}>
      <Button variant="text" color="primary" aria-label="menu" onClick={toggleDrawer(true)} sx={{ minWidth: '30px', p: '4px' }}>
        <MenuIcon />
      </Button>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <Box sx={{ minWidth: '60dvw', p: 2, backgroundColor: 'background.paper', flexGrow: 1, }}>
          <MenuItem ><MenuLink  to="/">Начало</MenuLink></MenuItem>
          <MenuItem ><MenuLink  to="/services">Услуги</MenuLink></MenuItem>          
          <MenuItem ><MenuLink  to="/aboutus">За нас</MenuLink></MenuItem>
          <MenuItem ><MenuLink  to="/contacts">Контакти</MenuLink></MenuItem>
          <MenuItem ><MenuLink  to="/carreers">Кариери</MenuLink></MenuItem>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 2, my: 3 }}>
            <CustomAvatar><LocalPhoneIcon color="primary" fontSize="small" /></CustomAvatar>
            <CustomAvatar><EmailIcon onClick={event =>  window.location.href='/contacts'} color="primary" fontSize="small" /></CustomAvatar>
            <CustomAvatar><LinkIcon color="primary" fontSize="small" /></CustomAvatar>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}