import * as React from 'react';
import AppNavBar from '../../components/AppNavBar';
import Main from './Main';
import Questions from './Questions'
import Contacts from '../../components/Contacts';
import Container from '@mui/material/Container';
import PreFooter from '../../components/PreFooter';
import BottomNavBar from '../../components/BottomNavBar';

export default function ContactPage() {
  return (
    <Container disableGutters maxWidth="auto">
      <AppNavBar />
      <Main/>
      <Questions/>      
      <Contacts />
      <PreFooter />
      <BottomNavBar />
    </Container>
  );
}
