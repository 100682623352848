import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CodeBlock, dracula } from 'react-code-blocks';
import { ReactComponent as Numbers } from "../svg/numbers-314x117.svg";
import { ReactComponent as Bar } from "../svg/barchart-229x91.svg";
import { ReactComponent as Deparment } from "../svg/departments-logos-393x114.svg";
import { ReactComponent as Pie } from "../svg/piechart-134x134.svg";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FaceIcon from '@mui/icons-material/Face';

const code = `$('#nav-galaxy-tab').on('shown.bs.tab', function (event) {
      if(!exportTestSystemTable) {
            loadTestSystemDataTable();
      }
});`;

export default function Hero() {
  return (
    <Grid container item xl={6} xm={12} xs={12} sx={{ dispay: { xl: 'none', md: 'none', xm: 'flex', xs: 'none' }, borderBottomLeftRadius: 30, borderBottom: '1px solid #d4d4d4', overflow: 'hidden' }}>

      <Grid item xl={7} sm={6} xm={6} xs={12}>
        <Card variant="outlined" sx={{ ml: {xl: 3, md: 3, sm: 0, xs: 0}, mb: {xl: 3, sm: 1, xs: 3}, mt:3, borderRadius: 2, backgroundColor: "primary.main" }}>
          <CardContent sx={{ '&:last-child': { px: 0, py: 2 } }}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <CheckCircleIcon sx={{ color: 'white', ml: 2 }} />
              </Grid>
              <Grid item sm={10} xs={9}>
                <Typography variant="body2" color="white" sx={{mx: 2}}>Ти избираш компания, която предлага <br />множество възможности за теб.</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card variant="outlined" sx={{ ml: {xl: 3, md: 3, sm: 0, xs: 0}, borderRadius: 2 }}>
          <CardContent sx={{ '&:last-child': { px: 0, py: 0 } }}>
            <SvgIcon viewBox='0 0 314 117' sx={{ mx: 2, width: 'auto', height: 'auto' }}>
              <Numbers />
            </SvgIcon>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xl={5} sm={6} xm={6} xs={12} sx={{mt:{xl: 3, sm: 3, xs:0}}}>
        <Box flexGrow={1} sx={{ mx: { xl: 3, xm: 3, sm: 3, xs: 0 }, my: { xl: 0, sm: 0, xm: 0, xs: 3 }, py: 3, px: 3, backgroundColor: 'white', borderRadius: 3, border: '1px solid #d4d4d4' }}>
          <Typography variant="body2" sx={{ fontWeight: 'bold', textAlign: 'center' }} >Седмична активност</Typography>
          <Typography variant="body2" sx={{ pb: 2, textAlign: 'center' }}>54 часа 48 минути</Typography>
          <SvgIcon viewBox='0 0 229 91' sx={{ width: 'auto', height: 'auto' }}>
            <Bar />
          </SvgIcon>
        </Box>
        <Stack direction="row" spacing={{xxl: 0.5, xl: 1 , md: 3, sm: 1, xs: 3}} sx={{my: {xl: 2, md: 1, sm:1, xs: 2}, mx:3}}>
          <Chip label="PHP" color="primary" variant="outlined" sx={{fontWeight:'bold'}}/>
          <Chip label="React" color="success" variant="outlined" sx={{fontWeight:'bold'}}/>
          <Chip label="HTML" color="error" variant="outlined" sx={{fontWeight:'bold'}}/>
          <Chip label="CSS" color="warning" variant="outlined" sx={{fontWeight:'bold', display:{xxl:'flex', xl:'flex', md:'flex', sm:'none'}}}/>          
        </Stack>
      </Grid>

      <Grid item xl={12} xm={12} xs={12} sx={{ mx: {xl: 3, md: 3, sm: 0, xs: 0}, mt: { xl:1, xm: 2, xs: 0 }, mb: { xm: 3, xs: 3 } }}>
        <CodeBlock text={code} language='javascript' showLineNumbers={true} theme={dracula} />
      </Grid>

      <Grid item xl={12} xm={12} xs={12} sx={{ml: {xl: 3, md: 3, sm: 0, xs: 0}, display: 'flex', flexDirection: { xl: 'row', xm: 'row', xs: 'column' }, mb: { xl: -8, xm: -5, xs: -1 } }}>
        <Box flexGrow={1} sx={{ mr: { xl: 3, xm: 3, xs: 0 }, mb: 3, py: 2, px: 2, backgroundColor: 'white', borderRadius: 2, border: '1px solid #d4d4d4' }}>
          <Typography variant="body2" sx={{ py: 0.5, fontSize: 12, textAlign: 'center' }}>NXTSP обединява три ключови отдела за маркетинг, уеб дизайн и IT.</Typography>
          <SvgIcon viewBox='0 0 420 140' sx={{ pl: 2, width: 'auto', height: 'auto' }}>
            <Deparment />
          </SvgIcon>
        </Box>
        <Box flexGrow={1} sx={{ mr:{ xl: 3, xm: 3, sm: 3, xs: 0 }, mb: { xl: 3, xs: 1 }, px: { xl: 1, xs: 10 }, backgroundColor: 'white', borderRadius: 2, border: '1px solid #d4d4d4' }}>
          <Typography variant="body2" sx={{ py: 1, fontSize: 12, textAlign: 'center' }}>Над 8 различни проекта.</Typography>
          <SvgIcon viewBox='0 0 134 134' sx={{ width: 'auto', height: { xl: '95px', xm: '95px', sm:'95px', xs: 'auto' }, ml: { xl: 4, sm: 14, xm: 0, xs: 0 } }}>
            <Pie />
          </SvgIcon>
        </Box>
      </Grid>


    </Grid>
  );
}