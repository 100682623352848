import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as HTML } from "../svg/html.svg";
import { ReactComponent as CSS } from "../svg/css-32x32.svg";
import { ReactComponent as JavaScript } from "../svg/javascript.svg";
import { ReactComponent as ReactI } from "../svg/react.svg";

const items = [
    {
        icon:  <SvgIcon viewBox='0 0 32 32' sx={{ width: '32px', height: '32px', mx: 2}}><HTML/></SvgIcon>,
        title: 'HTML',
        description:'Основа за структуриране на уеб статични страници.'        
    },
    {
        icon: <SvgIcon viewBox='0 0 32 32' sx={{ width: '32px', height: '32px', mx: 2}}><CSS/></SvgIcon>,
        title: 'CSS',
        description: 'Визуално оформление и стилизиране на уеб страници.'
        
    },
    {
        icon: <SvgIcon viewBox='0 0 32 32' sx={{ width: '32px', height: '32px', mx: 2}}><JavaScript/></SvgIcon>,
        title: 'JavaScript',
        description: 'Интерпретируем език за интерактивни уеб функции.',        
    },
    {
        icon: <SvgIcon viewBox='0 0 32 32' sx={{ width: '32px', height: '32px', mx: 2}}><ReactI/></SvgIcon>,
        title: 'React',
        description: 'Библиотека за динамични потребителски интерфейси.',        
    }    
];

export default items;