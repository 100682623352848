import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as N2SP } from "../svg/n2sp-service2-48x48.svg";
import { ReactComponent as Nexttospace } from "../svg/nexttospace-service3-48x48.svg";
import { ReactComponent as NXT2SP } from "../svg/nxt2sp-service1-48x48.svg";

const items = [
    {
        icon:  <SvgIcon viewBox='0 0 48 48' sx={{ width: '48px', height: '48px', mx: 2}}><NXT2SP/></SvgIcon>,
        title: 'NXT2SP - IT решения и софтуер',
        description:'Осигуряваме интелигентни IT решения, които преобразяват и оптимизират вашата работа.'        
    },
    {
        icon: <SvgIcon viewBox='0 0 48 48' sx={{ width: '48px', height: '48px', mx: 2}}>< N2SP /></SvgIcon>,
        title: 'N2SP - Уеб дизайн и изработка',
        description: 'Създаваме креативен, функционален уеб дизайн, който отговаря на вашата визия и цели.'
        
    },
    {
        icon: <SvgIcon viewBox='0 0 48 48' sx={{ width: '48px', height: '48px', mx: 2}}><Nexttospace /></SvgIcon>,
        title: 'NEXT TO SPACE - Маркетинг и реклама',
        description: 'Развиваме иновативни маркетинг стратегии за значително онлайн присъствие и максимален успех.',        
    },
];

export default items;

