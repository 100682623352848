import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import content1 from '../content/cardLine1';
import content2 from '../content/cardLine2';
import content3 from '../content/cardLine3';
import content4 from '../content/cardLine4';

const items1 = content1.slice(0, 2);
const items2 = content1.slice(2, 4);
const items3 = content2.slice(0, 2);
const items4 = content2.slice(2, 4);
const items5 = content3.slice(0, 2);
const items6 = content3.slice(2, 4);
const items7 = content4.slice(0, 2);
const items8 = content4.slice(2, 3);

function CartLineMobile1() {
  return (
    items1.map(({ icon, title, description }, index) => (
      <Card key={index} variant="outlined" sx={{ mt: 1, borderRadius: 4 }}>
        <CardContent sx={{ '&:last-child': { py: 3, px: 1 } }}>
          <Grid container alignItems="left">
            <Grid item xs={3}>
              {icon}
            </Grid>
            <Grid item xs={9}>
              <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary">
                {title}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: 11 }}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))
  );
}

function CartLineMobile2() {
  return (
    items2.map(({ icon, title, description }, index) => (
      <Card key={index} variant="outlined" sx={{ mt: 1, borderRadius: 4 }}>
        <CardContent sx={{ '&:last-child': { py: 3, px: 1 } }}>
          <Grid container alignItems="left">
            <Grid item xs={3}>
              {icon}
            </Grid>
            <Grid item xs={9}>
              <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary">
                {title}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: 11 }}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))
  );
}

function CartLineMobile3() {
  return (
    items3.map(({ icon, title, description }, index) => (
      <Card key={index} variant="outlined" sx={{ mt: 1, borderRadius: 4 }}>
        <CardContent sx={{ '&:last-child': { py: 3, px: 1 } }}>
          <Grid container alignItems="left">
            <Grid item xs={3}>
              {icon}
            </Grid>
            <Grid item xs={9}>
              <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary">
                {title}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: 11 }}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))
  );
}

function CartLineMobile4() {
  return (
    items4.map(({ icon, title, description }, index) => (
      <Card key={index} variant="outlined" sx={{ mt: 1, borderRadius: 4 }}>
        <CardContent sx={{ '&:last-child': { py: 3, px: 1 } }}>
          <Grid container alignItems="left">
            <Grid item xs={3}>
              {icon}
            </Grid>
            <Grid item xs={9}>
              <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary">
                {title}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: 11 }}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))
  );
}

function CartLineMobile5() {
  return (
    items5.map(({ icon, title, description }, index) => (
      <Card key={index} variant="outlined" sx={{ mt: 1, borderRadius: 4 }}>
        <CardContent sx={{ '&:last-child': { py: 3, px: 1 } }}>
          <Grid container alignItems="left">
            <Grid item xs={3}>
              {icon}
            </Grid>
            <Grid item xs={9}>
              <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary">
                {title}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: 11 }}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))
  );
}

function CartLineMobile6() {
  return (
    items6.map(({ icon, title, description }, index) => (
      <Card key={index} variant="outlined" sx={{ mt: 1, borderRadius: 4 }}>
        <CardContent sx={{ '&:last-child': { py: 3, px: 1 } }}>
          <Grid container alignItems="left">
            <Grid item xs={3}>
              {icon}
            </Grid>
            <Grid item xs={9}>
              <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary">
                {title}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: 11 }}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))
  );
}

function CartLineMobile7() {
  return (
    items7.map(({ icon, title, description }, index) => (
      <Card key={index} variant="outlined" sx={{ mt: 1, borderRadius: 4 }}>
        <CardContent sx={{ '&:last-child': { py: 3, px: 1 } }}>
          <Grid container alignItems="left">
            <Grid item xs={3}>
              {icon}
            </Grid>
            <Grid item xs={9}>
              <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary">
                {title}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: 11 }}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))
  );
}

function CartLineMobile8() {
  return (
    items8.map(({ icon, title, description }, index) => (
      <Card key={index} variant="outlined" sx={{ mt: 1, borderRadius: 4 }}>
        <CardContent sx={{ '&:last-child': { py: 3, px: 1 } }}>
          <Grid container alignItems="left">
            <Grid item xs={3}>
              {icon}
            </Grid>
            <Grid item xs={9}>
              <Typography sx={{ fontSize: 14, fontWeight: 'bold' }} color="text.secondary">
                {title}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: 11 }}>
                {description}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    ))
  );
}

export { CartLineMobile1, CartLineMobile2, CartLineMobile3, CartLineMobile4, CartLineMobile5, CartLineMobile6, CartLineMobile7, CartLineMobile8 };