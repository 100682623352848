import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import Avatar from '@mui/material/Avatar';
import styles from '../../styles/styles';


export default function Information() {
  return (
    <Grid container sx={styles.template} paddingY={4}> 

      <Grid item xxl={4} xl={4} md={4} xm={4} xs={12}>
        <Box sx={{ mb: 3}}>
          <Box>          
            <Typography color='black' sx={{ pt: 4, fontFamily: 'Roboto', fontSize: 22, fontWeight: 'bold', letterSpacing: 0.2, pb: 1 }}>Как услугите ни ви помагат</Typography>
            <Typography color='black' sx={{ py: 1 }}>< CheckIcon sx={styles.checkBlue}/>Увеличават разпознаваемостта на бранда ви</Typography>
            <Typography color='black' sx={{ py: 1 }}>< CheckIcon sx={styles.checkBlue}/>Привличат повече потенциални клиенти</Typography>
            <Typography color='black' sx={{ py: 1 }}>< CheckIcon sx={styles.checkBlue}/>Оптимизация на търсачките</Typography>
            <Typography color='black' sx={{ py: 1 }}>< CheckIcon sx={styles.checkBlue}/>Реализирате вашата бизнес идея</Typography>
            <Typography color='black' sx={{ py: 1 }}>< CheckIcon sx={styles.checkBlue}/>Винаги сте в крак с най-новите тенденции</Typography>
          </Box>
        </Box>
      </Grid>

      <Grid container item xxl={4} xl={4} md={4} xm={4} xs={12} sx={{alignItems:{xl:'center', xs: 'none'} }} direction="column">
        <Box sx={{ mb: 3}}>
          <Box sx={{ml: {xl: 3, md: 4, xs: 0}}}>
            <Typography color='black' sx={{ pt: 4, fontFamily: 'Roboto', fontSize: 22, fontWeight: 'bold', letterSpacing: 0.2, pb: 1 }}>С кого работим</Typography>
            <Typography color='black' sx={{ py: 1 }}>< CheckIcon sx={styles.checkBlue}/>Големи бизнеси</Typography>
            <Typography color='black' sx={{ py: 1 }}>< CheckIcon sx={styles.checkBlue}/>Малки бизнеси</Typography>
            <Typography color='black' sx={{ py: 1 }}>< CheckIcon sx={styles.checkBlue}/>Стартъпи</Typography>
            <Typography color='black' sx={{ py: 1 }}>< CheckIcon sx={styles.checkBlue}/>Публични личности</Typography>            
          </Box>
        </Box>
      </Grid>

      <Grid container item xxl={4} xl={4} md={4} xm={4} xs={12} direction="column">
        <Box sx={{ mb: 3}}>
          <Box>
            <Typography color='black' sx={{ pt: 4, fontFamily: 'Roboto', fontSize: 22, fontWeight: 'bold', letterSpacing: 0.2, pb: 1 }}>Най-търсените ни услуги</Typography>
            <Typography color='black' sx={{ py: 1 }}>< CheckIcon sx={styles.checkBlue}/>Изграждане на бизнес системи - CMS/ CRM</Typography>
            <Typography color='black' sx={{ py: 1 }}>< CheckIcon sx={styles.checkBlue}/>Мобилни, десктоп и уеб приложения</Typography>
            <Typography color='black' sx={{ py: 1 }}>< CheckIcon sx={styles.checkBlue}/>Изработка на уебсайт</Typography>
            <Typography color='black' sx={{ py: 1 }}>< CheckIcon sx={styles.checkBlue}/>Анализ на вашия уебсайт</Typography>
            <Typography color='black' sx={{ py: 1 }}>< CheckIcon sx={styles.checkBlue}/>Поддръжка на социални мрежи</Typography>
          </Box>
        </Box>
      </Grid>

    </Grid>
  );
}    