import * as React from 'react';
import CustomAvatar from '../components/CustomAvatar';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExtensionIcon from '@mui/icons-material/Extension';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ChatIcon from '@mui/icons-material/Chat';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FavoriteIcon from '@mui/icons-material/Favorite';

const items = [
  {
    icon: <CustomAvatar><DashboardIcon color="primary" fontSize="small" /></CustomAvatar>,
    title: 'Доказан процес',
    description: 'Започваме всеки проект с задълбочен разговор, за да разберем контекста на вашия бизнес, целевата аудитория, функционалните изисквания и техническите аспекти.'
  },
  {
    icon: <CustomAvatar><ExtensionIcon color="primary" fontSize="small" /></CustomAvatar>,
    title: 'Иновативни решения',
    description: 'Нашият екип ви води през всичко, което трябва да знаете, стъпка по стъпка. Анализът и планирането са допринесли за иновативни решения в някои от най-успешните фирми.'

  },
  {
    icon: <CustomAvatar><VerifiedUserIcon color="primary" fontSize="small" /></CustomAvatar>,
    title: 'Висок стандарт',
    description: 'Обръщаме внимание на всеки детайл в нашата работа, като се стремим към висок стандарт на изпълнение на всяка част от проекта.',
  },
  {
    icon: <CustomAvatar><ChatIcon color="primary" fontSize="small" /></CustomAvatar>,
    title: 'Обратна връзка',
    description: 'Нашият екип поддържа открита и постоянна комуникация с клиентите през всеки етап от жизнения цикъл на проекта.',
  },
  {
    icon: <CustomAvatar><SupportAgentIcon color="primary" fontSize="small" /></CustomAvatar>,
    title: 'Експертна поддръжка',
    description: 'Ние насърчаваме дългосрочни партньорства с нашите клиенти, осигурявайки поддръжка след завършването на всеки проект, за да гарантираме неговия успех. ',
  },
  {
    icon: <CustomAvatar><FavoriteIcon color="primary" fontSize="small" /></CustomAvatar>,
    title: 'Персонално обслужване',
    description: 'Взаимоотношенията, които имаме с нашите клиенти са много важни за нас. Подхождаме към вас лично и се фокусираме върху това което има значение - Вашият резултат ',
  },
];

export default items;