import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import styles from '../../styles/styles';


export default function Information() {
  return (
    <Grid spacing={3} container sx={styles.templateBorder} paddingY={9} justifyContent="flex-start" alignItems="left">

      <Grid item xl={6} md={12} xs={12}>
        <Box>
          <Typography sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', letterSpacing: 1, py: 1 }} variant="h4">Често задавани въпроси</Typography>
        </Box>

        <Box sx={{ my: 2 }}>
          <Accordion>
            <AccordionSummary expandIcon={<KeyboardArrowDownIcon style={{ color: 'blue' }}/>} aria-controls="panel1-content" id="panel1-header">
              <Typography sx={{ fontSize: 14 }}>Какви видове уебсайтове можете да създадете?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontSize: 14 }}>Ние предлагаме създаване на различни видове уебсайтове, включително корпоративни уебсайтове, онлайн магазини(E-commerce), блогове и други.</Typography>
            </AccordionDetails>
          </Accordion>

        </Box>

        <Box sx={{ my: 2 }}>
          <Accordion>
            <AccordionSummary expandIcon={<KeyboardArrowDownIcon style={{ color: 'blue' }}/>} aria-controls="panel1-content" id="panel1-header">
              <Typography sx={{ fontSize: 14 }}>Какъв е процесът на разработка на уебсайт?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontSize: 14 }}>Процесът на разработка включва събиране и анализ на данни, дизайн,
                разработка, тестване и пускане. Работим в непрекъсната комуникация с клиентите си, за да гарантираме, че уебсайтът отговаря на техните изисквания.</Typography>
            </AccordionDetails>
          </Accordion>

        </Box>

        <Box sx={{ my: 2 }}>
          <Accordion>
            <AccordionSummary expandIcon={<KeyboardArrowDownIcon style={{ color: 'blue' }}/>} aria-controls="panel1-content" id="panel1-header" >
              <Typography sx={{ fontSize: 14 }}>Предлагате ли поддръжка след изграждането на уебсайта?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontSize: 14 }}>Да, предлагаме поддръжка, която включва редовно обновяване и техническа поддръжка след тяхното изграждане.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Grid>

      <Grid item xl={6} md={12} xs={12} sx={{ mt: 7 }}>

        <Box sx={{ my: 2 }}>
          <Accordion>
            <AccordionSummary expandIcon={<KeyboardArrowDownIcon style={{ color: 'blue' }}/>} aria-controls="panel1-content" id="panel1-header" >
              <Typography sx={{ fontSize: 14 }}>Какви са възможностите за дизайн на уебсайт?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontSize: 14 }}>Нашите услуги за дизайн включват индивидуален дизайн, адаптивен дизайн за различни устройства и оптимизация за потребителски интерфейс.</Typography>
            </AccordionDetails>
          </Accordion>

        </Box>

        <Box sx={{ my: 2 }}>
          <Accordion>
            <AccordionSummary expandIcon={<KeyboardArrowDownIcon style={{ color: 'blue' }}/>} aria-controls="panel1-content" id="panel1-header">
              <Typography sx={{ fontSize: 14 }}>Каква е разликата между CRM и CMS системи и какво предлагате?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontSize: 14 }}>CRM системите са за управление на взаимоотношенията с клиенти, докато CMS
                системите са за управление на съдържанието на уебсайта. Предлагаме изграждане и интеграция на и двата вида системи..</Typography>
            </AccordionDetails>
          </Accordion>

        </Box>

        <Box sx={{ my: 2 }}>
          <Accordion>
            <AccordionSummary expandIcon={<KeyboardArrowDownIcon style={{ color: 'blue' }}/>} aria-controls="panel1-content" id="panel1-header">
              <Typography sx={{ fontSize: 14 }}>Има ли възможност за персонализирани решения според нашите нужди?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontSize: 14 }}>Да, работим с нашите клиенти, за да разберем техните нужди и да предложим персонализирани
                решения, които отговарят на техните изисквания.</Typography>
            </AccordionDetails>
          </Accordion>

        </Box>

      </Grid>

    </Grid>
  );
}    