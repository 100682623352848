import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


export default function Info() {
  return (
    <Grid container item xl={12} direction="row" justifyContent="flex-start" alignItems="start" sx={{ py: 2, px: { xxl: 48, xl: 26, md: 3, sm:2, xs: 1 }, backgroundColor: '#fafcff' }}>

      <Grid item xl={6} md={6} xs={12} display='flex' sx={{ py: 2 }}>
        <Box sx={{ mt: 2, mr: 2 }}><CheckCircleIcon color="primary" fontSize="medium" /></Box>
        <Box>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: "black" }}>Действай с фокус към клиента</Typography>
          <Typography sx={{ fontSize: 14, color: "black" }}>Наш приоритет е да задоволяваме и превъзхождаме очакванията на клиентите,
            като по този начин изграждаме тяхното доверие и лоялност.</Typography>
        </Box>
      </Grid>

      <Grid item xl={6} md={6} xs={12} display='flex' sx={{ py: 2, pl: { xl: 3, xs: 0 } }}>
        <Box sx={{ mt: 2, mr: 2 }}><CheckCircleIcon color="primary" fontSize="medium" /></Box>
        <Box>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: "black" }}>Работи в екипи с различни специалисти</Typography>
          <Typography sx={{ fontSize: 14, color: "black" }}>Тук всеки действа от името на цялата компания, не само от своя
            собствен екип, позволявайки ни да съчетаем уникалните си умения.</Typography>
        </Box>
      </Grid>

      <Grid item xl={6} md={6} xs={12} display='flex' sx={{ py: 2 }}>
        <Box sx={{ mt: 2, mr: 2 }}><CheckCircleIcon color="primary" fontSize="medium" /></Box>
        <Box>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: "black" }}>Бъди проактивен</Typography>
          <Typography sx={{ fontSize: 14, color: "black" }}>Насърчаваме проактивния подход и инициативността сред
            нашите служители, като сме отворени към нови идеи и възможности.</Typography>
        </Box>
      </Grid>


      <Grid item xl={6} md={6} xs={12} display='flex' sx={{ py: 2, pl: { xl: 3, xs: 0 } }}>
        <Box sx={{ mt: 2, mr: 2 }}> <CheckCircleIcon color="primary" fontSize="medium" /></Box>
        <Box>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: "black" }}>Взимай решения на място</Typography>
          <Typography sx={{ fontSize: 14, color: "black" }}>Подкрепяме бързото и обмислено вземане на решения,
            което спомага за адаптивността в работата ни..</Typography>
        </Box>
      </Grid>

      <Grid item xl={6} md={6} xs={12} display='flex' sx={{ py: 2 }}>
        <Box sx={{ mt: 2, mr: 2 }}><CheckCircleIcon color="primary" fontSize="medium" /></Box>
        <Box>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: "black" }}>Споделяй опит и давай обратна връзка</Typography>
          <Typography sx={{ fontSize: 14, color: "black" }}>Споделянето на опит и активната обратна връзка са ключови за нашето
            професионално развитие и укрепване на екипа..</Typography>
        </Box>
      </Grid>

      <Grid item xl={6} md={6} xs={12} display='flex' sx={{ py: 2, pl: { xl: 3, xs: 0 } }}>
        <Box sx={{ mt: 2, mr: 2 }}> <CheckCircleIcon color="primary" fontSize="medium" /></Box>
        <Box>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold', color: "black" }}>Влагай страст и се забавлявай</Typography>
          <Typography sx={{ fontSize: 14, color: "black" }}>Страстта и забавлението в работата са основа за позитивна атмосфера и подобряване
            на качеството на нашите продукти и услуги.</Typography>
        </Box>
      </Grid>

    </Grid>
  );
}