import React from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';

const CustomAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  backgroundColor: 'transparent',
  '&:hover': {
    boxShadow: '0px -1px 6px 0px rgba(46, 151, 255, 0.18), 0px 1px 6px 0px rgba(46, 151, 255, 0.18), 1px 0px 6px 0px rgba(46, 151, 255, 0.18), -1px 0px 6px 0px rgba(46, 151, 255, 0.18)'
  },
  border: '2px solid',
  borderColor: theme.palette.primary.main,
}));

export default CustomAvatar;