import * as React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import GroupsIcon from '@mui/icons-material/Groups';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import styles from '../../styles/styles';


export default function Values() {
  return (
    <Grid spacing={1} container sx={styles.template} paddingTop={6} paddingBottom={5} backgroundColor='#fafcff'>

      <Grid item xl={6} md={6} xs={12} sx={{pb: {xl: 10, xs: 0}}}>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', py: 1, color: "primary.main" }} variant="h4">NXTSP
          <Typography display={'inline'} sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', color: "black" }}> ценности</Typography>
        </Typography>
        <Typography>В NXTSP вярваме в иновациите като двигател на промяната и залагаме на сътрудничество,
          прозрачност и откритост във всяка връзка с клиентите и партньорите ни.
        </Typography>
      </Grid>

      <Grid container item xl={12} md={12} xm={12} xs={12} sx={{mx: {xl: 0, xm:1, xs: 4}, mt: 2}}>
        <Grid item xl={3} md={3} xm={3} xs={12} sx={{pb: {xl: 0, xs: 2}}}>
          < EmojiObjectsIcon sx={{ fontSize: 40, color: "primary.main", mb: 3 }} />
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', pb: 1 }}>Иновация</Typography>
          <Typography>Новаторството е основа за създаване на изключителни продукти.</Typography>
        </Grid>
        <Grid item xl={3} md={3} xm={3} xs={12} sx={{pb: {xl: 0, xs: 2}}}>
          < GroupsIcon  sx={{ fontSize: 40, color: "primary.main", mb: 3 }} />
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', pb: 1 }}>Екипност</Typography>
          <Typography>Споделяме знания и се учим един от друг за постигане на високи резултати.</Typography>
        </Grid>
        <Grid item xl={3} md={3} xm={3} xs={12} sx={{pb: {xl: 0, xs: 2}}}>
          < TrendingUpIcon  sx={{ fontSize: 40, color: "primary.main", mb: 3 }} />
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', pb: 1 }}>Развитие</Typography>
          <Typography>Посветени на учене за иновативни решения в динамичния свят.</Typography>
        </Grid>
        <Grid item xl={3} md={3} xm={3} xs={12} sx={{pb: {xl: 0, xs: 2}}}>
          < ChatBubbleIcon  sx={{ fontSize: 40, color: "primary.main", mb: 3 }} />
          <Typography sx={{ fontSize: 16, fontWeight: 'bold', pb: 1 }}>Откритост</Typography>
          <Typography>Откритата комуникация създава единство за успех. </Typography>
        </Grid>
      </Grid>

    </Grid>
  );
}    