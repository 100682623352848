import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CartServices from './CartServices';
import Typography from '@mui/material/Typography';
import styles from '../styles/styles';


export default function Tools() {
    return (
        <Grid container item xl={8} xs={12} md={12} sx={styles.container1}>
            <Box>
                <Typography sx={styles.mainBold} variant="h6">Инструменти</Typography>
                <Typography variant="h4" sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', color: "primary.main", my: 1 }}>Успех
                    <Typography display={'inline'} sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold', color: "black" }}> чрез технологични иновации</Typography>
                </Typography>
                <Typography sx={styles.body2}>
                    В NXTSP, използваме различни технологии и инструменти, за да създаваме иновативни уеб и маркетингови решения.
                </Typography>
            </Box>
        </Grid>
    );
}