import * as React from 'react';
import Grid from '@mui/material/Grid';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomAvatar from '../components/CustomAvatar';
import MenuLink from './MenuLink';
import styles from '../styles/styles';

export default function BottomNavBar() {
    return (
        <Container maxWidth="xxl" sx={{ top: 'auto', bottom: 0, boxShadow: 0, bgcolor: 'white', borderTop: 1, borderColor: 'divider' }}>

            <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ display: { xl: 'none', md: 'none', xs: 'flex' } }}>
                <Grid item xs={12} sx={{ m: 3 }}>
                    <Typography sx={styles.menuFooter}>NEXT TO SPACE © 2024  Всички права запазени</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', gap: { xl: 2, sm: 1, xm: 1, xs: 1 }, alignItems: 'center' }}>
                        <Typography><MenuLink sx={styles.menuFooter} to="/terms">Общи условия</MenuLink></Typography>
                        <Typography><MenuLink sx={styles.menuFooter} to="/cookies">Бисквитки</MenuLink></Typography>
                        <Typography><MenuLink sx={styles.menuFooter} to="/privacy">Политика за поверителност</MenuLink></Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ mt: 3 }}>
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <CustomAvatar><FacebookIcon color='primary' sx={{ cursor: 'pointer', fontSize: 24 }} onClick={event => window.location.href = 'https://www.facebook.com/nexttospace'} /></CustomAvatar>
                        <CustomAvatar><InstagramIcon color='primary' sx={{ cursor: 'pointer', fontSize: 24 }} /></CustomAvatar>
                        <CustomAvatar><LinkedInIcon color='primary' sx={{ cursor: 'pointer', fontSize: 24 }} onClick={event => window.location.href = 'https://www.linkedin.com/company/nxtsp/'} /></CustomAvatar>
                    </Box>
                </Grid>
            </Grid>

            <Toolbar sx={{ my: { xl: 0, md: 0, xm: -5, xs: -5 } }}>
                <Box sx={{ flexGrow: 1, alignItems: 'center' }}>
                    <Box sx={{ display: { xl: 'flex', md: 'flex', xs: 'none' }, gap: 3, alignItems: 'center', pl: { xxl: 34, xl: 16 } }}>
                        <Typography sx={styles.menuFooter}>NEXT TO SPACE © 2024  Всички права запазени</Typography>
                    </Box>
                </Box>
                <Box sx={{ flexGrow: 1, alignItems: 'center', mr: { xxl: 24, xl: 24 } }}>
                    <Box sx={{ display: { xl: 'flex', md: 'flex', xs: 'none' }, gap: 3, alignItems: 'center' }}>
                        <Typography><MenuLink sx={styles.footerText} to="/terms">Общи условия</MenuLink></Typography>
                        <Typography><MenuLink sx={styles.footerText} to="/cookies">Бисквитки</MenuLink></Typography>
                        <Typography><MenuLink sx={styles.footerText} to="/privacy">Политика за поверителност</MenuLink></Typography>
                    </Box>
                </Box>
                <Box sx={{ display: { xl: 'flex', xs: 'none', md: 'flex', gap: 8 }, mr: { xl: 20, md: 0 } }}>
                    <CustomAvatar><FacebookIcon color='primary' sx={{ cursor: 'pointer', fontSize: 24 }} onClick={event => window.location.href = 'https://www.facebook.com/nexttospace'} /></CustomAvatar>
                    <CustomAvatar><InstagramIcon color='primary' sx={{ cursor: 'pointer', fontSize: 24 }} /></CustomAvatar>
                    <CustomAvatar><LinkedInIcon color='primary' sx={{ cursor: 'pointer', fontSize: 24 }} onClick={event => window.location.href = 'https://www.linkedin.com/company/nxtsp/'} /></CustomAvatar>
                </Box>
            </Toolbar>
        </Container>
    );
}