import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import styles from '../styles/styles';


export default function Information() {
    return (
        <Grid container alignItems="center" sx={{ backgroundColor: 'black' }}>
            <Box sx={{ display: { xl: 'none', md: 'none', xm:'none', xs: 'flex' } }}>

                <Grid container item xl={2} xs={7} sx={{ mt: 8, mx: {xl: 4, sm: 6, xs: 2} }}>
                    <Box sx={{ mb: 2 }}>
                        <Typography color='#99CCF3' sx={{ mx: 1, fontSize: 24, fontWeight: 'bold', textAlign:{ xs: 'center'} }}>120</Typography>
                        <Typography color='#9198A3' sx={{ mx: 1, fontSize: 14, mb: 2 }}>Реализирани мисии</Typography>
                    </Box>
                    <Box>
                        <Typography color='#99CCF3' sx={{ mx: 1, fontSize: 24, fontWeight: 'bold', textAlign:{ xs: 'center'} }}>14</Typography>
                        <Typography color='#9198A3' sx={{ mx: 1, fontSize: 14 }}>Креативни проекти</Typography>
                    </Box>
                </Grid>

                <Grid container item xl={2} xs={5} sx={{ mt: 8 }}>
                    <Box sx={{ mb: 2 }}>
                        <Typography color='#99CCF3' sx={{ mr: 3, fontSize: 24, fontWeight: 'bold', textAlign:{ xs: 'center'} }}>3</Typography>
                        <Typography color='#9198A3' sx={{ mx: 1, fontSize: 14, mb: 2 }}>Различни отдела</Typography>
                    </Box>
                    <Box>
                        <Typography color='#99CCF3' sx={{ mx: 1, fontSize: 24, fontWeight: 'bold', textAlign:{ xs: 'center'} }}>5</Typography>
                        <Typography color='#9198A3' sx={{ mx: 1, fontSize: 14 }}>Години опит</Typography>
                    </Box>
                </Grid>

            </Box>

            <Grid item xxl={8} xl={7} xs={12} sx={styles.container1}>
                <Typography color='white' sx={{ textAlign: { xl: 'left', xs: 'center' }, m: { xl: 0, md: 2, xs: 0 }, py: 5 }}>
                    "Като NXTSP, искам да подчертая важността на инвестициите в
                    оптимизирането и технологичните иновации. В свят, където технологиите определят успеха,
                    инвестирането в ефективност и новаторски решения е ключът към бъдещето на
                    вашия бизнес. Това не само подобрява конкурентоспособността
                    ви, но и отваря врати към нови възможности за растеж и
                    просперитет на вашата компания".
                </Typography>
                <Box alignItems="center" sx={{ justifyContent: { xl: 'flex-start', xs: 'center' }, display: 'flex' }}>
                    <Avatar sx={{ width: 60, height: 60, border: 2, borderColor: 'white' }} src="/boss-image-2.jpg"></Avatar>
                    <Typography color='white' sx={{ mx: 1, fontWeight: 'bold' }} variant="h7">Васил Боснешки<br />
                        <Typography display='inline' color='white' sx={{ fontWeight: '300' }}>CEO</Typography>
                    </Typography>
                </Box>
            </Grid>

            {/*xl-large*/}
            <Grid item xs={6} xxl={3} xl={2} sx={{ mx: {xxl: -18, xl: 6}, display: { xl: 'inline', md: 'none', xm:'none', xs: 'none' } }}>
                <Box alignItems="center" sx={{ borderLeft: 1, borderColor: '#515457'}}>
                    <Typography color='#99CCF3' sx={{ mx: 3, fontSize: 32, fontWeight: 'bold' }}>120</Typography>
                    <Typography color='#9198A3' sx={{ mx: 3, mb: 8, fontSize: 16, fontWeight: '300', py: -4, mb: 4 }}>Реализирани мисии</Typography>
                </Box>
                <Box sx={{ borderLeft: 1, borderColor: '#515457', mb: 5 }}>
                    <Typography color='#99CCF3' sx={{ mx: 3, fontSize: 32, fontWeight: 'bold' }}>14</Typography>
                    <Typography color='#9198A3' sx={{ mx: 3 }}>Креативни проекти</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} xl={2} sx={{ mx: -8, xl: 8, display: { xl: 'inline', xs: 'none' } }}>
                <Box alignItems="center" sx={{ borderLeft: 1, borderColor: '#515457' }}>
                    <Typography color='#99CCF3' sx={{ mx: 3, fontSize: 32, fontWeight: 'bold' }}>3</Typography>
                    <Typography color='#9198A3' sx={{ mx: 3, py: -4, mb: 4 }}>Различни отдела</Typography>
                </Box>
                <Box sx={{ borderLeft: 1, borderColor: '#515457', mb: 5 }}>
                    <Typography color='#99CCF3' sx={{ mx: 3, fontSize: 32, fontWeight: 'bold' }}>5</Typography>
                    <Typography color='#9198A3' sx={{ mx: 3 }}>Години опит</Typography>
                </Box>
            </Grid>

            {/*medium*/}
            <Grid container item sx={{ display: { xl: 'none', md: 'flex', xm:'flex', xs: 'none' }, mx: {md: 28, xm: 12}, my: 4 }} direction='row' justifyContent='space-between' alignItems='center'>
                <Box sx={{ borderLeft: 1, borderColor: '#515457' }}>
                    <Typography color='#99CCF3' sx={{ mx: 1, fontSize: 24, fontWeight: 'bold' }}>120</Typography>
                    <Typography color='#9198A3' sx={{ mx: 1, fontSize: 14 }}>Реализирани мисии</Typography>
                </Box>
                <Box sx={{ borderLeft: 1, borderColor: '#515457' }}>
                    <Typography color='#99CCF3' sx={{ mx: 1, fontSize: 24, fontWeight: 'bold' }}>14</Typography>
                    <Typography color='#9198A3' sx={{ mx: 1, fontSize: 14 }}>Креативни проекти</Typography>
                </Box>
                <Box sx={{ borderLeft: 1, borderColor: '#515457' }}>
                    <Typography color='#99CCF3' sx={{ mx: 1, fontSize: 24, fontWeight: 'bold' }}>3</Typography>
                    <Typography color='#9198A3' sx={{ mx: 1, fontSize: 14 }}>Различни отдела</Typography>
                </Box>
                <Box sx={{ borderLeft: 1, borderColor: '#515457' }}>
                    <Typography color='#99CCF3' sx={{ mx: 1, fontSize: 24, fontWeight: 'bold' }}>5</Typography>
                    <Typography color='#9198A3' sx={{ mx: 1, fontSize: 14 }}>Години опит</Typography>
                </Box>
            </Grid>
        </Grid>
    );
}