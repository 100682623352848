import { red } from '@mui/material/colors';
import "@fontsource/ibm-plex-sans";
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,     
      sm: 450,
      xm: 700,
      md: 900,
      lg: 1200,
      xl: 1436,
      xxl: 1800
    },
  },
  typography: {
    fontFamily: [
      'IBM Plex Sans',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#2e97ff',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
