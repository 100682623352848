import * as React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/scrollToTop";
import Home from "./components/Home";
import ServicePage from "./pages/services/ServicePage";
import ContactPage from "./pages/contacts/ContactPage";
import CarreersPage from "./pages/careers/CarreersPage";
import JobsSeoPage from "./pages/jobs/JobsSeoPage";
import JobsBackPage from "./pages/jobs/JobsBackPage";
import JobsFrontPage from "./pages/jobs/JobsFrontPage";
import AboutPage from "./pages/aboutus/AboutPage";
import CookiesPage from "./pages/cookies/CookiesPage";
import TermsPage from "./pages/terms/TermsPage";
import PrivacyPage from "./pages/privacy/PrivacyPage";

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<ServicePage />} />
        <Route path="/aboutus" element={<AboutPage />} />
        <Route path="/contacts" element={<ContactPage />} />
        <Route path="/carreers" element={<CarreersPage />} />
        <Route path="/job-backend-developer" element={<JobsBackPage />} />
        <Route path="/job-frontend-developer" element={<JobsFrontPage />} />
        <Route path="/job-seo" element={<JobsSeoPage />} />
        <Route path="/cookies" element={<CookiesPage />} />
        <Route path="/terms" element={<TermsPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
      </Routes>
    </Router>
  );
}
