import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import styles from '../../styles/styles';


export default function DescriptionOneFront() {
  return (
    <Grid spacing={1} container sx={styles.template} paddingTop={14}>

      <Grid item xl={8} md={6} xs={12} overflow='hidden'>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 32, fontWeight: 'bold' }} variant="h4">Front-End Developer</Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 18, py: 2 }}>Next To Space се стремим към прогрес и иновации, разработвайки системи, 
        които трансформират взаимодействието ни с технологиите и бизнес логиките. Нашата корпоративна мисия е насочена към създаването на 
        продукти и решения, предназначени да ни приближат с една стъпка повече към бъдещето.
        </Typography>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 18, pb: 1 }}>В рамките на тази мисия, активно търсим да разширим нашия екип със 
        завършен и ангажиран Front-End Developer, който е готов да допринесе не само в разработването на външни клиентски проекти, 
        но и в създаването и усъвършенстването на нашите вътрешни системи.
        </Typography>

        <Box sx={{ pt: 6 }}>
          <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">Детайли за позицията</Typography>
          <List sx={{ listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}><b>Локация:</b> гр. Пловдив, ул. Рогошко шосе №36</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}><b>Работно време: </b>Осемчасов работен ден - от понеделник до петък; 9:00 до 18:00</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}><b>Вид на работата: </b>Постоянна работа</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}><b>Години опит: </b>от 3 до 10+</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}><b>Отпуск: </b>20 дни</Typography></ListItem>
          </List>
        </Box>

        <Box sx={{ pt: 6 }}>
          <Typography sx={{ fontFamily: 'Roboto', fontSize: 24, fontWeight: 'bold' }} variant="h4">Отговорности</Typography>
          <List sx={{ listStyleType: 'disc' }}>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Създаване на интерактивни уеб страници и приложения, които предоставят отличен потребителски интерфейс и изживяване.</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Разработване адаптивни уеб страници за различни устройства.</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Сътрудничество с дизайнери и Back-End разработчици, за да се осигури техническа осъществимост на концепциите за дизайн.</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Провеждане на тестове и диагностициране на проблеми.</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Проучване на нови технологии и методи за подобряване на процесите и възможностите на уеб разработката.</Typography></ListItem>
            <ListItem sx={{ display: 'list-item', ml: 3 }}><Typography sx={{ fontFamily: 'Roboto', fontSize: 16 }}>Осигуряване, че уеб страниците се създават в съответствие с най-добрите практики и стандарти за уеб разработка.</Typography></ListItem>             
          </List>
        </Box>

      </Grid>

    </Grid>
  );
}    