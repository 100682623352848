import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as Jquery } from "../svg/jquery.svg";
import { ReactComponent as PHP } from "../svg/php.svg";
import { ReactComponent as SASS } from "../svg/sass.svg";
import { ReactComponent as MySQL } from "../svg/mysql.svg";
import { ReactComponent as Photoshop } from "../svg/photoshop.svg";
import { ReactComponent as Canva } from "../svg/canva.svg";
import { ReactComponent as DaVinci } from "../svg/davinci.svg";

const items = [
    {
        icon:  <SvgIcon viewBox='0 0 32 32' sx={{ width: '32px', height: '32px', mx: 2}}><Jquery/></SvgIcon>,
        title: 'jQuery',
        description:'Най-популярната JavaScript библиотека с отворен код.'        
    },
    {
        icon: <SvgIcon viewBox='0 0 32 32' sx={{ width: '32px', height: '32px', mx: 2}}><PHP/></SvgIcon>,
        title: 'PHP',
        description: 'Криптов език за сървърно програмиране.'
        
    },
    {
        icon: <SvgIcon viewBox='0 0 32 32' sx={{ width: '32px', height: '32px', mx: 2}}><SASS/></SvgIcon>,
        title: 'SASS',
        description: 'Използван за каскадни шаблони, CSS препроцесор.',        
    },
    {
        icon: <SvgIcon viewBox='0 0 32 32' sx={{ width: '32px', height: '32px', mx: 2}}><MySQL/></SvgIcon>,
        title: 'MySQL',
        description: 'Система за управление на релационни бази данни.',        
    },    
];

export default items;