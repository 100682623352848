import * as React from 'react';
import AppNavBar from '../../components/AppNavBar';
import Container from '@mui/material/Container';
import PreFooter from '../../components/PreFooter';
import BottomNavBar from '../../components/BottomNavBar';
import DescriptionOneBack from './DescriptionOneBack';
import DescriptionTwoBack from './DescriptionTwoBack';
import { Helmet } from 'react-helmet';
import Form from './Form';

export default function JobsUiPage() {
  return (
    <div>     
      <Container disableGutters maxWidth="auto">
        <AppNavBar />
        <DescriptionOneBack />
        <DescriptionTwoBack />
        <Form />
        <PreFooter />
        <BottomNavBar />
      </Container>
    </div>
  );
}
