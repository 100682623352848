import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Link } from 'react-router-dom';
import Hero from './Hero';
import styles from '../styles/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Main() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    console.log(matches);
    return (
        <Grid container sx={{ px: {  xxl: 36, xl: 12, lg: 12, md: 12, sm: 3, xs: 2 }, pr: { xl: 8, sm: 2, xs: 2 }, mt: 0, borderBottom: '1px solid #d4d4d4', py: {xxl: 10, xl: 3 }}} overflow='hidden'>
            <Grid item xl={6} md={6} sm={12} xm={6} xs={12} sx={{ pl: { xl: 14 }, py: {xl:3, xs: 0}, textAlign: { md: 'inherit', sm: 'center', xs: 'center' }, ml: {xl: -2, md: 0, xs: 0}}}>
                <Typography sx={{ fontFamily: 'Roboto', fontSize: {xl: 64, xs: 56}, fontWeight: 'bold', mt: {xl: 10, xs: 0}, letterSpacing: 0}} color="primary" variant="h1">Дигитални</Typography>
                <Typography sx={{ fontFamily: 'Roboto', fontSize: {xl: 64, xs: 56}, fontWeight: 'bold', letterSpacing: 0 }} variant="h1">Бизнес</Typography>
                <Typography sx={{ fontFamily: 'Roboto', fontSize: {xl: 64, xs: 56}, fontWeight: 'bold', letterSpacing: 0, lineHeight: 1 }} variant="h1">Решения</Typography>
                <Typography sx={{my: 1}}>
                    Ние създаваме уникални дигитални продукти <br/>и ефективни маркетинг решения за вашия бизнес.
                </Typography>
                <Box sx={{ display: { xl: 'flex', md: 'none', sm: 'none', xm:'none', xs: 'flex' }, flexDirection: { xs: 'column', md: 'row', xm:'row' }, alignItems: 'center', flexGrow: 1, gap: 3, my: 2, ml: {xl:0, xm: 10}}} >
                    <Button endIcon={<KeyboardArrowRightIcon />} component={Link} variant="contained" to="/services" color="primary" sx={{ size: { lg: 'large', xs: 'small' }, width: { xs: '100%', xm: '40%', md: '40%' }, height: '3.5em', borderRadius: 8, fontSize: 13 }}>Разгледай услугите ни</Button>
                    <Button variant="outlined" color="primary" component={Link} to="/contacts" sx={{ size: { lg: 'large', xs: 'small' }, width: { xs: '100%', md: '40%', xm: '40%' }, height: '3.5em', border: 2, borderRadius: 8, fontSize: 13, backgroundColor: '#fafcff' }}>Заяви консултация</Button>
                </Box>
            </Grid>
            <Grid container item md={6} sm={12} xm={6} sx={{display: { xl: 'none', md: 'flex', sm: 'inline',  xm: 'flex', xs: 'none' }}} direction="column" justifyContent="center" alignItems="center">
                <Button endIcon={<KeyboardArrowRightIcon />} component={Link} variant="contained" to="/services" color="primary" sx={{ size: 'large', borderRadius: 8, fontSize: {xl: 13, sm: 12}, m: 2 }}>Разгледай услугите ни</Button>
                <Button variant="outlined" color="primary"  component={Link} to="/contacts" sx={{ size: 'large', border: 2, borderRadius: 8, fontSize: 13, m: 2, backgroundColor: '#fafcff' }}>Заяви консултация</Button>
            </Grid>
            <Hero/>
        </Grid>
    );
}