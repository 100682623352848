import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { GlobalStyles } from '@mui/material';
import App from './App';
import theme from './theme';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

const globalStyles = (
  <GlobalStyles
    styles={(theme) => ({        
      body: {
        margin: 0, 
        padding:0       
      }      
    })}
  />
);

root.render(
  <ThemeProvider theme={theme}>   
    <CssBaseline />
    {globalStyles}
    <App />
  </ThemeProvider>,
);
