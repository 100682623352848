import * as React from 'react';
import WorkIcon from '@mui/icons-material/Work';
import CustomAvatar from '../components/CustomAvatar';
import PersonIcon from '@mui/icons-material/Person';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import GroupsIcon from '@mui/icons-material/Groups';

const items = [
    {
        icon:  <CustomAvatar><WorkIcon color="primary" fontSize="small" /></CustomAvatar>,
        title: 'Опит и знания',
        description:'Нашата дългогодишна експертиза гарантира надеждни и ефективни решения. Изграждаме устойчиви стратегии.'        
    },
    {
        icon: <CustomAvatar><PersonIcon color="primary" fontSize="small" /></CustomAvatar>,
        title: 'Индивидуален подход',
        description: 'Нашите решения са специално адаптирани спрямо всеки клиент. Чрез точност и внимание влагаме страст към всеки проект.'
        
    },
    {
        icon: <CustomAvatar><EmojiObjectsIcon color="primary" fontSize="small" /></CustomAvatar>,
        title: 'Технологични иновации',
        description: 'Постоянно внедряваме нови технологии за да бъдете по-успешни. Бъдете винаги една крачка напред с нашите иновативни решения.',        
    },
    {
        icon: <CustomAvatar><GroupsIcon color="primary" fontSize="small" /></CustomAvatar>,
        title: 'Надеждно партньорство',
        description: 'Нашата амбиция е изграждането на дългосрочни партньорства, утвърждаващи доверие и висок професионализъм. ',        
    },
];

export default items;