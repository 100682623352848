import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import CustomAvatar from '../components/CustomAvatar';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'; 
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search'; 
import LeaderboardIcon from '@mui/icons-material/Leaderboard';


const items = [
    {
        icon: <CustomAvatar><DesignServicesIcon color="primary" fontSize="small" /></CustomAvatar>,
        title: 'Изготвяне на уеб дизайн',
        description: 'Индивидуален уеб дизайн, перфектно съобразен с вашите нужди.'
    },
    {
        icon: <CustomAvatar><IntegrationInstructionsIcon color="primary" fontSize="small" /></CustomAvatar>,
        title: 'Изработка на уебсайт',
        description: 'Персонализирани уебсайтове, насочени към вашите специфични бизнес цели.'

    },
    {
        icon: <CustomAvatar><SettingsIcon color="primary" fontSize="small" /></CustomAvatar>,
        title: 'Поддръжка на уебсайтове',
        description: 'Поддръжка и актуализация за висока производителност.',
    },
    {
        icon: <CustomAvatar><SearchIcon color="primary" fontSize="small" /></CustomAvatar>,
        title: 'SEO оптимизация',
        description: 'Искате ли потенциалните ви клиенти да ви намерят в WEB? SEO е решението.',
    },
    {
        icon: <CustomAvatar><LeaderboardIcon color="primary" fontSize="small" /></CustomAvatar>,
        title: 'Анализ на вашия уебсайт ',
        description: 'Подробен анализ и стратегии за оптимизация за зареждането на вашия уебсайт.',
    },
];

export default items;

