import * as React from 'react';
import Main from './Main';
import AppNavBar from './AppNavBar';
import Services from './Services';
import Tools from './Tools';
import Information from './Information';
import Contacts from './Contacts';
import CartMain from './CartMain';
import CardMainBox from './CardMainBox';
import Container from '@mui/material/Container';
import PreFooter from './PreFooter';
import BottomNavBar from './BottomNavBar';

export default function Home() {
  return (
    <Container disableGutters maxWidth="auto">
      <AppNavBar />
      <Main />
      <Services />
      <Tools />
      <CartMain />
      <CardMainBox />
      <Information />
      <Contacts />
      <PreFooter />
      <BottomNavBar />
    </Container>
  );
}
